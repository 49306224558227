import * as React from 'react';
import { SVGProps } from 'react';

const SvgChevronUp = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m12 11.414-4-4-4 4L2.586 10 8 4.586 13.414 10 12 11.414Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgChevronUp;
