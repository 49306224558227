import { createContext, useContext } from 'react';

interface MarkdownCardContext {
  inCard: boolean;
  href: string | null;
}

const MarkdownCardContextInstance = createContext<MarkdownCardContext>({
  inCard: false,
  href: null,
});

const MarkdownCardContextProvider = MarkdownCardContextInstance.Provider;

const useMarkdownCardContext = () => {
  return useContext(MarkdownCardContextInstance);
};

export {
  MarkdownCardContextProvider,
  type MarkdownCardContext,
  useMarkdownCardContext,
};
