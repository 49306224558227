import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';
import {
  asideSyntaxTransformer,
  divSyntaxTransformer,
  htmlAnchorSyntaxTransformer,
  htmlSyntaxTransfomer,
  htmlTableSyntaxTransfomer,
  htmlVideoSyntaxTransformer,
  specialConstantsSyntaxTransfomers,
  tocSyntaxTransformer,
  tooltipSyntaxTransformer,
  youtubeSyntaxTransformer,
} from '@squareup/dex-ui-shared-markdown-components';

const syntaxTransformers: Array<MarkdownSyntaxTransformer> = [
  specialConstantsSyntaxTransfomers,
  tocSyntaxTransformer,
  asideSyntaxTransformer,
  tooltipSyntaxTransformer,
  htmlAnchorSyntaxTransformer,
  htmlTableSyntaxTransfomer,
  divSyntaxTransformer,
  htmlSyntaxTransfomer,
  youtubeSyntaxTransformer,
  htmlVideoSyntaxTransformer,
];
export { syntaxTransformers };
