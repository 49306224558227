import { TocSection } from '@squareup/dex-types-shared-docs';
import { Link } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { markdownNodeStyles } from '@squareup/dex-ui-shared-markdown-components';
import clsx from 'clsx';
import React, { FC, ReactNode } from 'react';

import styles from './table-of-contents.module.css';

interface LargeTableOfContentsProps {
  sections: Array<TocSection>;
  heading?: ReactNode;
}

const LargeTableOfContents: FC<LargeTableOfContentsProps> = ({
  sections,
  heading,
}) => {
  if (sections.length === 0) {
    return null;
  }

  return (
    <>
      {heading}
      <Box as="ul" data-test-toc-type="large">
        {sections.map((section, i) => {
          return (
            <Box
              key={`${section.name}_${i}`}
              as="li"
              className={clsx(
                styles['toc-item'],
                section.level >= 3 && styles.indent
              )}
            >
              <Link
                omitFocusStyling={true}
                href={`#${section.id}`}
                trackingId={'sidebar-link-clicked'}
                className={markdownNodeStyles['markdown-link']}
              >
                {section.name}
              </Link>
            </Box>
          );
        })}
      </Box>
    </>
  );
};

export { LargeTableOfContents };
