import { Box, Paragraph30 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './aside.module.css';

type AsideType = 'info' | 'warning' | 'success' | 'important' | 'tip';

interface AsideProps {
  type: AsideType;
}

const asideTypeHeaderMap = new Map<AsideType, string>([
  ['info', 'Note'],
  ['warning', 'Warning'],
  ['success', 'Success'],
  ['important', 'Important'],
  ['tip', 'Did you know?'],
]);

const Aside: FC<PropsWithChildren<AsideProps>> = ({ children, type }) => {
  return (
    <Box
      testId="aside"
      data-test-type={type}
      border={{ radius: 'standard' }}
      shadow="10"
      padding={{ vertical: '2x', horizontal: '2x' }}
      margin={{ vertical: '4x' }}
      className={clsx(styles.aside, styles[type])}
    >
      <Paragraph30 weight="semi-bold" as="h4" margin={{ bottom: '1x' }}>
        {asideTypeHeaderMap.get(type)}
      </Paragraph30>
      <Box testId="aside-content">{children}</Box>
    </Box>
  );
};

export { Aside, type AsideType };
