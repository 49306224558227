import { NodeType } from '@markdoc/markdoc';
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { FC, PropsWithChildren } from 'react';

interface MarkdownBannedProps {}

// These are elements that are banned from being used in our DEX markdown
const MarkdownBanned: FC<PropsWithChildren<MarkdownBannedProps>> = ({
  children: _,
}) => {
  return null;
};

const bannedItems: Array<MarkdownNode> = (['hr'] as Array<NodeType>).map(
  (nodeType) => {
    return {
      node: {
        nodeType,
        schema: {
          render: 'MarkdownBanned',
          children: ['inline'],
        },
      },
      component: {
        name: 'MarkdownBanned',
        value: MarkdownBanned,
      },
    };
  }
);

export { bannedItems, MarkdownBanned };
