import {
  OnClickProp,
  NullableClassName,
  FocusProps,
} from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { ReactNode } from 'react';

import { focusRingStyles } from '../FocusRing';

import styles from './native-button.module.css';

interface NativeButtonProps {
  children: ReactNode;
  testId?: string | undefined;
}

interface DisabledButtonProp {
  disabled?: boolean;
}

const NativeButton = React.forwardRef<
  HTMLButtonElement,
  NativeButtonProps &
    NullableClassName &
    OnClickProp &
    React.HTMLAttributes<HTMLButtonElement> &
    React.DOMAttributes<HTMLButtonElement> &
    TrackedProps &
    FocusProps &
    DisabledButtonProp &
    Partial<Pick<BoxProps, 'border' | 'margin' | 'padding' | 'as'>>
>(
  (
    {
      children,
      onClick,
      className,
      testId,
      trackingId,
      trackingExtra,
      omitFocus,
      omitFocusSpacing,
      focusBorderRadius,
      as,
      ...rest
    },
    ref
  ) => {
    return (
      <Box<HTMLButtonElement>
        as={as || 'button'}
        border={{ radius: 'standard' }}
        data-testid={testId}
        data-tracking-id={trackingId}
        data-tracking-extra={trackingExtra}
        className={clsx(
          styles.button,
          !rest.border && styles['no-border'],
          !rest.padding && styles['button-padding'],
          !omitFocus && focusRingStyles['focus-ring'],
          focusBorderRadius &&
            focusRingStyles[`focus-ring-${focusBorderRadius}`],
          omitFocusSpacing && focusRingStyles['focus-ring-omit-spacing'],
          className
        )}
        onClick={onClick}
        ref={ref}
        {...rest}
      >
        {children}
      </Box>
    );
  }
);

NativeButton.displayName = 'NativeButton';

export { NativeButton };
