import { createContext, useContext } from 'react';

interface MarkdownCardLayoutContext {
  // This is to ensure downstream cards can style themselves appropriately according to
  // the layout without needing to show both the narrow and the desktop cards
  isNarrowLayout: boolean;
}

const MarkdownCardLayoutContextInstance =
  createContext<MarkdownCardLayoutContext>({
    isNarrowLayout: false,
  });

const MarkdownCardLayoutContextProvider =
  MarkdownCardLayoutContextInstance.Provider;

const useMarkdownCardLayoutContext = () => {
  return useContext(MarkdownCardLayoutContextInstance);
};

export {
  MarkdownCardLayoutContextProvider,
  type MarkdownCardLayoutContext,
  useMarkdownCardLayoutContext,
};
