import { Config, Node, RenderableTreeNode, Tag } from '@markdoc/markdoc';
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { TableHead } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownTableHeadProps {}

const MarkdownTableHead: FC<PropsWithChildren<MarkdownTableHeadProps>> = ({
  children,
}) => {
  // Don't render the table head if there's no actual headers in it
  if (!children) {
    return <></>;
  } else {
    return <TableHead>{children}</TableHead>;
  }
};

// We don't want to render the Table Head if there's no
// text in it. This is because in markdown, doing `|||\n|-|-|` will
// produce an empty Table Head. Since we add a sticky shadow to it, this will cause problems
function hasChildren(nodes: RenderableTreeNode[]): boolean {
  if (nodes.length === 0) {
    return false;
  }

  let nextLevel: RenderableTreeNode[] = [];
  for (const node of nodes) {
    if (!Tag.isTag(node) && node?.toString()?.length) {
      return true;
    }

    if (Tag.isTag(node)) {
      nextLevel = [...nextLevel, ...node.children];
    }
  }

  return hasChildren(nextLevel);
}

const schema = {
  render: 'MarkdownTableHead',
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    return new Tag(
      this.render,
      attributes,
      hasChildren(children) ? children : []
    );
  },
};

const tableHead: MarkdownNode = {
  node: {
    nodeType: 'thead',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownTableHead,
  },
};

export { tableHead };
