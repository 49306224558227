import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Aside, AsideType } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownAsideProps {
  type: AsideType;
}

const MarkdownAside: FC<PropsWithChildren<MarkdownAsideProps>> = ({
  type,
  children,
}) => {
  return <Aside type={type}>{children}</Aside>;
};

const schema = {
  render: 'MarkdownAside',
  attributes: {
    type: { type: String, required: true, default: 'info' },
  },
};

const aside: MarkdownComponent = {
  tag: {
    name: 'aside',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownAside,
  },
};

export { aside };
