import { Config, Node, Tag } from '@markdoc/markdoc';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { TabAnimator, Tabset } from '@squareup/dex-ui';
import React, { FC, ReactNode, useCallback, useState } from 'react';

import { tab as markdownTabSchema } from './MarkdownTab';
import styles from './markdown-tabset.module.css';

interface TabMetadata {
  id: string;
  label: string;
  contents?: ReactNode;
}

interface MarkdownTabsetProps {
  tabsMetadata?: Array<TabMetadata>;
}

const MarkdownTabset: FC<MarkdownTabsetProps> = ({ tabsMetadata = [] }) => {
  const [currentTabId, setCurrentTabId] = useState<string>(
    tabsMetadata?.[0]?.id || ''
  );

  const onTabChanged = useCallback(
    (newTabId: string) => {
      setCurrentTabId(newTabId);
    },
    [setCurrentTabId]
  );

  if (tabsMetadata.length === 0) {
    return null;
  }

  const tabset = (
    <Tabset
      className={styles.tabset}
      smallTabsetItem={true}
      tabs={tabsMetadata.map((tab) => ({
        id: tab.id,
        label: tab.label,
      }))}
      currentTabId={currentTabId}
      onTabChanged={onTabChanged}
    ></Tabset>
  );

  return (
    <>
      {tabset}
      <TabAnimator
        className={styles['tab-content']}
        currentTab={currentTabId}
        tabs={tabsMetadata.map((tab) => {
          return {
            tabId: tab.id,
            contents: tab.contents,
          };
        })}
      />
    </>
  );
};

const schema = {
  render: 'MarkdownTabset',
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    // We need to collect all the tabs components in the children, and
    // group them in an appropriate datastructure for the TabAnimator
    const tabsMetadata = children
      .filter(
        (child): child is Tag =>
          Tag.isTag(child) && child.name === markdownTabSchema.component.tagName
      )
      .map((child) => {
        return {
          id: child.attributes.id,
          label: child.attributes.id,
          contents: child,
        };
      });

    return new Tag(this.render, {
      ...attributes,
      tabsMetadata,
    });
  },
};

const tabset: MarkdownComponent = {
  tag: {
    name: 'tabset',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownTabset,
  },
};

export { tabset };
