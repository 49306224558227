import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Paragraph10 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { focusRingStyles } from '../FocusRing';
import { Link } from '../Link';

import styles from './doc-badge.module.css';

interface DocBadgeProps {
  href?: string | undefined;
}

const LinkWrapper: FC<PropsWithChildren<DocBadgeProps & TrackedProps>> = ({
  children,
  href,
  trackingId,
}) => {
  return href ? (
    <Link passHref omitAnchor={true} href={href} trackingId={trackingId}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

const DocBadge: FC<
  PropsWithChildren<DocBadgeProps & NullableClassName & TrackedProps>
> = ({ children, href, trackingId, className }) => {
  return (
    <LinkWrapper href={href} trackingId={trackingId}>
      <Paragraph10
        as={href ? 'a' : 'div'}
        className={clsx(
          styles['doc-badge'],
          href && styles.href,
          href && focusRingStyles['focus-ring'],
          href && focusRingStyles['focus-ring-12'],
          className
        )}
        weight="semi-bold"
        padding={{ vertical: '0.25x', horizontal: '1x' }}
        margin={{ right: '1x' }}
        border={{ radius: '12' }}
        colorVariant="20"
      >
        {children}
      </Paragraph10>
    </LinkWrapper>
  );
};

export { DocBadge };
