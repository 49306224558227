import * as React from 'react';
import { SVGProps } from 'react';

const SvgCopy = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11 6a1 1 0 1 0 0 2h5v6a1 1 0 1 0 2 0V8a2 2 0 0 0-2-2h-5ZM9 16v-5h4v5H9Zm-2-6a1 1 0 0 1 1-1h6a1 1 0 0 1 1 1v7a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1v-7Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgCopy;
