import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

/**
 * Here are the kinds of basic HTML we will convert
 * - <strong> -> **
 * - </strong> -> **
 * - <em> -> _
 * - </em> -> _
 * - <div> -> ""
 * - </div> -> ""
 * - <p> -> ""
 * - </p> -> ""
 */

const strongOpenRegex = /<strong[\d\s"=A-Za-z-]*>/g;
const strongCloseRegex = /<\/strong[\d\s"=A-Za-z-]*>/g;

const emOpenRegex = /<em[\d\s"=A-Za-z-]*>/g;
const emCloseRegex = /<\/em[\d\s"=A-Za-z-]*>/g;

const pOpenRegex = /<p[\d\s":=A-Za-z-]*>/g;
const pCloseRegex = /<\/p[\d\s"=A-Za-z-]*>/g;

const supOpenRegex = /<sup[\d\s":=A-Za-z-]*>/g;
const supCloseRegex = /<\/sup[\d\s"=A-Za-z-]*>/g;

const codeOpenRegex = /<code[\d\s"=A-Za-z-]*>/g;
const codeCloseRegex = /<\/code[\d\s"=A-Za-z-]*>/g;

const spanOpenRegex = /<span[\d\s"()*,./=A-Z[\]a-z{}-]*.*>/g;
const spanCloseRegex = /<\/span[\d\s"=A-Za-z-]*>/g;

const divOpenRegex = /<div[\d\s"()*,./=A-Z[\]a-z{}-]*.*>/g;
const divCloseRegex = /<\/div[\d\s"=A-Za-z-]*>/g;

const ulOpenRegex = /<ul[\d\s"=A-Za-z-]*>/g;
const ulCloseRegex = /<\/ul[\d\s"=A-Za-z-]*>/g;

const liOpenRegex = /<li[\d\s"=A-Za-z-]*>/g;
const liCloseRegex = /<\/li[\d\s"=A-Za-z-]*>/g;

const brTag = /<br[\d\s"/:=a-z-]*>/gi;

const styleRegex = /<style[\d\s"=A-Za-z-]*>[\S\s]*?<\/style>/g;

const regexToReplacement = [
  {
    regex: strongOpenRegex,
    replacement: '**',
  },
  {
    regex: strongCloseRegex,
    replacement: '**',
  },
  {
    regex: emOpenRegex,
    replacement: '_',
  },
  {
    regex: emCloseRegex,
    replacement: '_',
  },
  {
    regex: pOpenRegex,
    replacement: '',
  },
  {
    regex: pCloseRegex,
    replacement: '',
  },
  {
    regex: supOpenRegex,
    replacement: '',
  },
  {
    regex: supCloseRegex,
    replacement: '',
  },
  {
    regex: codeOpenRegex,
    replacement: '`',
  },
  {
    regex: codeCloseRegex,
    replacement: '`',
  },
  {
    regex: spanOpenRegex,
    replacement: '',
  },
  {
    regex: spanCloseRegex,
    replacement: '',
  },
  {
    regex: divOpenRegex,
    replacement: '',
  },
  {
    regex: divCloseRegex,
    replacement: '',
  },
  // Lists are mimicking markdoc syntax - https://markdoc.dev/docs/tags#table-with-rich-content
  {
    regex: ulOpenRegex,
    replacement: '\n  {% list %}\n',
  },
  {
    regex: ulCloseRegex,
    replacement: '  {% /list %}',
  },
  {
    regex: liOpenRegex,
    replacement: '  * ',
  },
  {
    regex: liCloseRegex,
    replacement: '\n',
  },
  {
    regex: brTag,
    replacement: '{% line-break /%}',
  },
  {
    regex: styleRegex,
    replacement: '',
  },
];

/**
 * Simple syntax transformer to convert basic HTML to markdown
 */
const htmlSyntaxTransfomer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    if (!content) {
      return '';
    }

    let result = content;
    for (const { regex, replacement } of regexToReplacement) {
      result = result?.replaceAll(regex, (original: string) => {
        if (highlight) {
          return highlightText(original);
        }

        return replacement;
      });
    }

    return result;
  },
};

export { htmlSyntaxTransfomer };
