import {
  MarkdownContext,
  useMarkdownContext,
} from '@squareup/dex-markdown-context-provider';
import { Paragraph30 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { focusRingStyles } from '../FocusRing';

import styles from './carousel.module.css';

const EmptyLinkWrapper: Exclude<MarkdownContext['LinkComponent'], null> = ({
  children,
}) => <>{children}</>;

interface CarouselCardProps {
  title?: string | undefined;
  href: string;
}

const CarouselCard: FC<PropsWithChildren<CarouselCardProps>> = ({
  children,
  title,
  href,
}) => {
  const markdownContext = useMarkdownContext();
  const LinkWrapper = markdownContext.LinkComponent || EmptyLinkWrapper;

  return (
    <LinkWrapper href={href} trackingId="carousel-card">
      <Paragraph30
        testId={`carousel-card-${href}`}
        className={clsx(styles.card, focusRingStyles['focus-ring'])}
        as="a"
        padding={{ vertical: '2x', horizontal: '2x' }}
        border="standard"
      >
        {title && <Paragraph30 weight="semi-bold">{title}</Paragraph30>}
        {children}
      </Paragraph30>
    </LinkWrapper>
  );
};

export { CarouselCard };
