import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC } from 'react';

interface MarkdownLineBreakProps {}

// Temporary line break component to work around the use of <br>
// in the old content
const MarkdownLineBreak: FC<MarkdownLineBreakProps> = () => {
  return <Box as="br"></Box>;
};

const schema = {
  render: 'MarkdownLineBreak',
};

const lineBreak: MarkdownComponent = {
  tag: {
    name: 'line-break',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownLineBreak,
  },
};

export { lineBreak };
