import { NullableClassName, OnClickProp } from '@squareup/dex-types-shared-ui';
import { Box, Heading20, Paragraph30 } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, useCallback, useRef } from 'react';

import { NativeButton } from '../NativeButton';

import { animationDuration } from './TabAnimator';
import { TabItem } from './tab-types';
import { tabLabelToAriaRoleId } from './tab-utils';
import styles from './tab.module.css';

interface TabsetProps {
  tabs: Array<TabItem>;
  currentTabId: string;
  smallTabsetItem?: boolean | undefined;
  onTabChanged: (tabId: string) => void;
}

interface TabsetItemProps {
  selected: boolean;
  last?: boolean | undefined;
  label: string;
  small?: boolean | undefined;
}

const TabsetItem: FC<PropsWithChildren<TabsetItemProps & OnClickProp>> = ({
  children,
  selected,
  onClick,
  last,
  label,
  small,
}) => {
  return (
    <Box
      className={clsx(
        styles['tabset-item'],
        last && styles.last,
        small && styles.small
      )}
    >
      <NativeButton
        trackingId="tabset-item"
        trackingExtra={JSON.stringify({ label })}
        testId="tabset-item"
        onClick={onClick}
        role="tab"
        aria-label={label}
        aria-selected={selected || undefined}
        // The panel doesn't exist until selected. To conform to the spec, we can only set
        // the aria-controls value if the id of the panel exists in the DOM
        aria-controls={selected ? tabLabelToAriaRoleId(label) : undefined}
      >
        {small ? (
          <Paragraph30
            weight="semi-bold"
            colorVariant="20"
            className={clsx(selected && styles.selected)}
            padding={{ bottom: '1x' }}
          >
            {children}
          </Paragraph30>
        ) : (
          <Heading20
            colorVariant="20"
            className={clsx(selected && styles.selected)}
            padding={{ bottom: '1.5x' }}
          >
            {children}
          </Heading20>
        )}
      </NativeButton>
      {selected && (
        <Box
          className={clsx(styles.underline)}
          border={{
            radius: { topLeft: '6', topRight: '6', bottomLeft: '6' },
          }}
        ></Box>
      )}
    </Box>
  );
};

const Tabset: FC<TabsetProps & NullableClassName> = ({
  tabs,
  currentTabId,
  onTabChanged,
  className,
  smallTabsetItem = false,
}) => {
  // We must wait for the animation from the tabs to finish before allowing
  // the user to switch again
  const tabsetChangeLockoutRef = useRef<boolean>(false);

  const onTabsetItemClicked = useCallback(
    (id: string) => {
      return () => {
        if (tabsetChangeLockoutRef.current) {
          return;
        }

        tabsetChangeLockoutRef.current = true;
        setTimeout(() => {
          tabsetChangeLockoutRef.current = false;
        }, animationDuration);
        onTabChanged(id);
      };
    },
    [onTabChanged]
  );

  return (
    <>
      <Box
        className={clsx(styles.tabset, className)}
        role="tablist"
        testId="tabset"
        margin={{ bottom: '4x' }}
      >
        {/* Placing the underline here because the overflow on the tabset ensures the selection of the tabset item
        can't appear over the border */}
        <Box
          className={styles['tabset-underline']}
          border={{ line: { top: 'standard' } }}
        ></Box>
        {tabs.map((item, i) => {
          return (
            <TabsetItem
              selected={item.id === currentTabId}
              key={item.id}
              onClick={onTabsetItemClicked(item.id)}
              last={i === tabs.length - 1}
              label={item.label}
              small={smallTabsetItem}
            >
              {item.label}
            </TabsetItem>
          );
        })}
      </Box>
    </>
  );
};

export { Tabset };
