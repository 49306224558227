import * as React from 'react';
import { SVGProps } from 'react';

const SvgDown = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.414 13 12 17.414 7.586 13 9 11.586l2 2V7h2v6.586l2-2L16.414 13Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgDown;
