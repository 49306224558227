import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { CarouselCard } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownCarouselCardProps {
  title?: string | undefined;
  href: string;
}

const MarkdownCarouselCard: FC<
  PropsWithChildren<MarkdownCarouselCardProps>
> = ({ children, title, href }) => {
  return (
    <CarouselCard title={title} href={href}>
      {children}
    </CarouselCard>
  );
};

const schema = {
  render: 'MarkdownCarouselCard',
  attributes: {
    href: { type: String, required: true },
    title: { type: String, required: false },
  },
};

const carouselCard: MarkdownComponent = {
  tag: {
    name: 'carousel-card',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCarouselCard,
  },
};

export { carouselCard };
