import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { Table } from '@squareup/dex-ui';
import { isScrollableX } from '@squareup/dex-utils-dom';
import React, {
  FC,
  PropsWithChildren,
  useEffect,
  useRef,
  useState,
} from 'react';

interface MarkdownTableProps {}

const MarkdownTable: FC<PropsWithChildren<MarkdownTableProps>> = ({
  children,
}) => {
  const tableRef = useRef<HTMLElement>(null);
  const [isStickyHeader, setIsStickyHeader] = useState(false);

  useEffect(() => {
    function onResize() {
      requestAnimationFrame(() => {
        // Headers are only sticky if the content is not horizontally scrollable
        if (tableRef.current && !isScrollableX(tableRef.current)) {
          setIsStickyHeader(true);
        } else {
          setIsStickyHeader(false);
        }
      });
    }

    onResize();
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [tableRef]);

  return (
    <Table
      ref={tableRef}
      margin={{ vertical: '5x' }}
      hasStickyHeader={isStickyHeader}
    >
      {children}
    </Table>
  );
};

const schema = {
  render: 'MarkdownTable',
};

const table: MarkdownNode = {
  node: {
    nodeType: 'table',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownTable,
  },
};

export { table };
