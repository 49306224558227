import { Tokenizer } from '@markdoc/markdoc';

type Token = ReturnType<Tokenizer['tokenize']>[number];

/**
 * We're going to support a potential markdoc syntax for image attributes.
 * The problem right now is there isn't any way to actually pass attributes to markdoc
 * image syntax, because there's no block scope.
 * We're going to follow the 2nd options here since that's what they are
 * leaning towards - https://github.com/markdoc/markdoc/issues/156#issuecomment-1580979257
 * In effect, we're allowing this syntax:
 * ```
 * ![image alt here {% width="100" height="100" %}](image-url.png)
 * ```
 * @param imgToken
 * @returns A new imgToken with the attributes parsed
 */
const parseImageAttrsForToken = (
  imgToken: Token,
  tokenizer: Tokenizer
): Token => {
  if (!imgToken.content?.includes('{% ')) {
    return imgToken;
  }

  const resultToken = imgToken;

  // Need to re-tokenize the content itself to parse out the annotation we created
  const attrsTokens = tokenizer.tokenize(imgToken.content);
  // Find the parent token
  const parentToken = attrsTokens.find(
    (token) => token.type === 'inline' && !token.tag
  );

  // The content is now the first text token available
  const content = parentToken?.children
    ?.find((token) => token.type === 'text')
    ?.content?.trimEnd();

  // Attributes are always found in the empty annotation node, within the meta attributes
  const attrs = parentToken?.children?.find(
    (token) => token.type === 'annotation'
  )?.meta;

  resultToken.content = content || '';
  resultToken.meta = attrs;

  return resultToken;
};

const processTokens = (tokens: Token[], tokenizer: Tokenizer): Token[] => {
  const newTokens: Token[] = [];

  for (const token of tokens) {
    let result = token;
    if (token.type === 'image') {
      result = parseImageAttrsForToken(token, tokenizer);
    } else if (token.type === 'inline' && token.children !== null) {
      result.children = processTokens(token.children, tokenizer);
    }

    newTokens.push(result);
  }

  return newTokens;
};

export { processTokens, parseImageAttrsForToken };
