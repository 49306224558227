import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC } from 'react';

import styles from './markdown-anchor.module.css';

interface MarkdownAnchorProps {
  id: string;
}

const MarkdownAnchor: FC<MarkdownAnchorProps> = ({ id }) => {
  return (
    <Box className={styles.container} as="span">
      <Box as="a" id={id} className={styles.anchor} />
    </Box>
  );
};

const schema = {
  render: 'MarkdownAnchor',
  attributes: {
    id: { type: String, required: true, default: '' },
  },
};

const anchor: MarkdownComponent = {
  tag: {
    name: 'anchor',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownAnchor,
  },
};

export { anchor };
