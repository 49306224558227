import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { DocResourceInfo } from '@squareup/dex-ui';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-resource-info.module.css';

interface MarkdownResourceInfoProps {
  iconPath: string;
  iconAlt: string;
  title?: string;
}

const MarkdownResourceInfo: FC<
  PropsWithChildren<MarkdownResourceInfoProps>
> = ({ children, iconPath, iconAlt, title }) => {
  return (
    <DocResourceInfo
      className={clsx(styles['markdown-resource-info'], styles.resource)}
      iconPath={iconPath}
      iconAlt={iconAlt}
      title={title}
    >
      {children}
    </DocResourceInfo>
  );
};

const schema = {
  render: 'MarkdownResourceInfo',
  attributes: {
    iconPath: { type: String, required: true },
    iconAlt: { type: String, required: true },
    title: { type: String, required: false },
  },
};

const resourceInfo: MarkdownComponent = {
  tag: {
    name: 'resource-info',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownResourceInfo,
  },
};

export { resourceInfo };
