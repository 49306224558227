import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { IconContainer } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownIconContainerProps {
  iconPath: string;
  iconAlt?: string;
  iconWidth?: number;
  iconHeight?: number;
}

const MarkdownIconContainer: FC<
  PropsWithChildren<MarkdownIconContainerProps>
> = ({ iconPath, iconAlt, iconWidth, iconHeight, children }) => {
  return (
    <IconContainer
      iconPath={iconPath}
      iconAlt={iconAlt}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
    >
      {children}
    </IconContainer>
  );
};

const schema = {
  render: 'MarkdownIconContainer',
  attributes: {
    iconPath: { type: String, required: true },
    iconAlt: { type: String, required: false },
    iconWidth: { type: Number, required: false },
    iconHeight: { type: Number, required: false },
  },
};

const iconContainer: MarkdownComponent = {
  tag: {
    name: 'icon-container',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownIconContainer,
  },
};

export { iconContainer };
