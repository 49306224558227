import { Config, Node, RenderableTreeNode, Tag } from '@markdoc/markdoc';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { DocCard } from '@squareup/dex-ui';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { useMarkdownCardLayoutContext } from './MarkdownCardLayoutProvider';
import { cardLinkOut } from './MarkdownCardLinkOut';
import { MarkdownCardContextProvider } from './MarkdownCardProvider';
import styles from './markdown-card.module.css';

interface MarkdownCardProps {
  // The src path for the icon
  iconPath?: string;
  // Alt text for the icon
  iconAlt?: string;
  // The width of the icon
  iconWidth?: number;
  // The height of the icon
  iconHeight?: number;
  // A general link for the card or the card link out component
  href?: string;
  // Forces the card to be borderless
  forceBorderless?: boolean;
  // Allows different padding dimensions for the card
  paddingSize?: 'small' | 'medium' | 'none' | undefined;
  // Allows different hover styles for the card
  hoverStyle?: 'shadow' | 'darken' | 'none' | undefined;
  // Disables the card from being a link when narrow
  disableHrefOnNarrow?: boolean | undefined;
  // Disables the card from being a link itself
  disableCardHref?: boolean | undefined;
  // Disables or enables a border on the icon
  iconBorder?: boolean | undefined;
  // Whether the card is currently in a tab. MarkdownTab will set this automatically
  inTab?: boolean | undefined;
  // Optional argument to set the aria-label for the link
  // If not set, we will grab it from the card content
  linkLabel?: string | undefined;
}

const trackingId = 'markdown-card';

const MarkdownCard: FC<
  PropsWithChildren<MarkdownCardProps & NullableClassName>
> = ({
  children,
  iconPath,
  href,
  iconAlt,
  hoverStyle,
  iconBorder,
  iconWidth,
  iconHeight,
  forceBorderless = false,
  paddingSize = 'medium',
  disableHrefOnNarrow = false,
  disableCardHref = false,
  inTab = false,
  linkLabel,
}) => {
  const { isNarrowLayout } = useMarkdownCardLayoutContext();

  const largeCard = (
    <DocCard
      className={clsx(styles.card, inTab && styles['in-tab'])}
      testId="doc-card-desktop"
      iconPath={iconPath}
      iconAlt={iconAlt}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
      href={disableCardHref ? undefined : href}
      iconBorder={iconBorder}
      hoverStyle={hoverStyle}
      borderless={forceBorderless}
      paddingSize={paddingSize}
      trackingId={trackingId}
      linkLabel={linkLabel}
    >
      {children}
    </DocCard>
  );

  const narrowCard = (
    <DocCard
      className={clsx(styles.card)}
      testId="doc-card-narrow"
      iconPath={iconPath}
      iconAlt={iconAlt}
      iconWidth={iconWidth}
      iconHeight={iconHeight}
      href={disableHrefOnNarrow || disableCardHref ? undefined : href}
      trackingId={trackingId}
      iconBorder={iconBorder}
      hoverStyle={hoverStyle}
      borderless={true}
      // Medium padding means no padding when narrow
      paddingSize={paddingSize === 'medium' ? 'none' : 'small'}
      linkLabel={linkLabel}
    >
      {children}
    </DocCard>
  );

  return (
    <MarkdownCardContextProvider value={{ inCard: true, href: href || null }}>
      {isNarrowLayout && iconPath ? narrowCard : largeCard}
    </MarkdownCardContextProvider>
  );
};

const schema = {
  render: 'MarkdownCard',
  attributes: {
    iconPath: { type: String, required: false },
    iconAlt: { type: String, required: false },
    href: { type: String, required: false },
    forceBorderless: { type: Boolean, required: false },
    paddingSize: { type: String, required: false },
    hoverStyle: { type: String, required: false },
    disableHrefOnNarrow: { type: Boolean, required: false },
    iconBorder: { type: Boolean, required: false },
    disableCardHref: { type: Boolean, required: false },
    inTab: { type: Boolean, required: false },
    iconWidth: { type: Number, required: false },
    iconHeight: { type: Number, required: false },
  },
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    // card link outs mean the narrow cards don't need to be hrefs.
    // Therefore the user just clicks the link out itself
    if (
      children.some(
        (child): child is Tag =>
          Tag.isTag(child) && child.name === cardLinkOut.component.tagName
      )
    ) {
      attributes.disableHrefOnNarrow = true;
    }

    // If the linkLabel isn't set, we will try to grab it from the card content, assuming there's
    // a "strong" tag available. If there isn't, we'll leave it empty
    if (!attributes.linkLabel && attributes.href) {
      let childrenToProcess = children.map((child) => child);
      while (childrenToProcess.length > 0) {
        const child = childrenToProcess.shift();
        if (!child) {
          break;
        }

        if (Tag.isTag(child)) {
          if (child.name === 'strong') {
            attributes.linkLabel = child.children[0]?.toString() || undefined;
            break;
          } else {
            childrenToProcess = [...childrenToProcess, ...child.children];
          }
        }
      }
    }

    const newChildren: RenderableTreeNode[] = [];
    let iconContainerGridNode: RenderableTreeNode | undefined;
    // The idea here is that we will always group icons in a card
    // into their own grid to maintain the 2-col grid. However,
    // this way we don't need to force writers to actually wrap it in a layout
    for (const child of children) {
      if (Tag.isTag(child) && child.name === 'MarkdownIconContainer') {
        if (!iconContainerGridNode) {
          iconContainerGridNode = new Tag(
            'MarkdownGrid',
            {
              dataName: 'icon-container-grid',
            },
            []
          );
        }

        (iconContainerGridNode as Tag).children.push(child);
      } else {
        if (iconContainerGridNode) {
          newChildren.push(iconContainerGridNode);
          iconContainerGridNode = undefined;
        }

        newChildren.push(child);
      }
    }

    if (iconContainerGridNode) {
      newChildren.push(iconContainerGridNode);
    }

    return new Tag(this.render, attributes, newChildren);
  },
};

const card: MarkdownComponent = {
  tag: {
    name: 'card',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCard,
  },
};

export { card, MarkdownCard };
