import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

interface TableHeaderRegexGroup {
  width?: string;
  colspan?: number;
  content?: string;
}

/**
 * Here are the kinds of table HTML we need to convert
 * - <table> -> {% table %}\n
 * - </table> -> \n{% /table %}
 * - </tr><tr> -> \n---\n
 * - <table><tr> -> \n---\n
 * - <tr> -> ""
 * - </tr> -> ""
 * - <td> -> |
 * - </td><td> -> |
 * - </td> -> |
 * - <thead> -> ""
 * - </thead> -> ""
 * - <th> -> "* "
 * - </th> -> \n
 */

const tableOpenRegex = /<table[\s"=A-Za-z-]*>\s*/g;
const tableCloseRegex = /<\/table[\s"=A-Za-z-]*>/g;
const tableRowCloseOpenRegex =
  /<\/tr[\s"=A-Za-z-]*>[\s"=A-Za-z]*<tr[\s"=A-Za-z-]*>\s*/g;
// Handles when there's no header
const tableOpenRowOpenRegex =
  /<table[\s"=A-Za-z-]*>[\s"=A-Za-z]*<tr[\s"=A-Za-z-]*>\s*/g;
const tableRowOpenRegex = /<tr[\s"-=A-Za-z-]*>\s*/g;
const tableRowCloseRegex = /<\/tr[\s"=A-Za-z-]*>\s*/g;
const tableDataOpenRegex = /<td[\d\s"%=A-Za-z-]*>\s*/g;
const tableDataCloseRegex = /<\/td[\s"=A-Za-z-]*>\s*/g;
const tableDataCloseOpenRegex =
  /<\/td[\s"=A-Za-z-]*>[\s"=A-Za-z]*<td[\s"=A-Za-z-]*>\s*/g;
const tableHeadRegex = /<\/?thead[\s"=A-Za-z-]*>\s*/g;
const tableBodyOpenRegex = /<tbody[\s"=A-Za-z-]*>\s*/g;
const tableBodyCloseRegex = /<\/tbody[\s"=A-Za-z-]*>\s*/g;

const tableHeaderRegex =
  /<th\s*(style="width: *(?<width>[\d %a-z]+);?")?(colspan="(?<colspan>\d+)")?\s*>(?<content>[\d .A-z…-]*)?<\/th[\s"=A-Za-z-]*>\s*/g;

const regexToReplacement = [
  {
    regex: tableOpenRowOpenRegex,
    replacement: '{% table %}\n---\n',
  },
  {
    regex: tableOpenRegex,
    replacement: '{% table %}\n',
  },
  {
    regex: tableCloseRegex,
    replacement: '\n{% /table %}',
  },
  {
    regex: tableHeadRegex,
    replacement: '',
  },
  {
    regex: tableRowCloseOpenRegex,
    replacement: '\n---\n',
  },
  {
    regex: tableRowOpenRegex,
    replacement: '',
  },
  {
    regex: tableRowCloseRegex,
    replacement: '',
  },
  {
    regex: tableDataCloseOpenRegex,
    replacement: '\n* ',
  },
  {
    regex: tableDataOpenRegex,
    replacement: '* ',
  },
  {
    regex: tableDataCloseRegex,
    replacement: '\n',
  },
  {
    regex: tableBodyOpenRegex,
    replacement: '',
  },
  {
    regex: tableBodyCloseRegex,
    replacement: '',
  },
];

/**
 * Simple syntax transformer that turns the aside syntax of !!!<type>\n<content>\n!!! into
 * {% aside type="<type>" %}<content>{% /aside %}
 *
 * This is done with a simple regex that treats !!![A-Za-z]+ as the start, and !!! as the end
 */
const htmlTableSyntaxTransfomer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    if (!content) {
      return '';
    }

    let result = content;
    for (const { regex, replacement } of regexToReplacement) {
      result = result?.replaceAll(regex, (original: string) => {
        if (highlight) {
          return highlightText(original);
        }

        return replacement;
      });
    }

    result = result?.replaceAll(
      tableHeaderRegex,
      (original: string, ...args: unknown[]) => {
        if (highlight) {
          return highlightText(original);
        }

        // Last arg is always the named group
        const groups: TableHeaderRegexGroup = args
          ? (args[args.length - 1] as TableHeaderRegexGroup)
          : { content: '' };

        let attrs = '';
        if (groups.width || groups.colspan) {
          const width = groups.width ? `width="${groups.width}"` : '';
          const colspan = groups.colspan ? ` colspan=${groups.colspan}` : '';
          attrs = ` {% ${width}${colspan} %}`;
        }

        return `* ${groups.content || ''}${attrs}\n`;
      }
    );

    return result;
  },
};

export { htmlTableSyntaxTransfomer };
