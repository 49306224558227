import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { TableData, TableDataProps } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownTableDataProps {}

const MarkdownTableData: FC<
  PropsWithChildren<MarkdownTableDataProps & TableDataProps>
> = ({ children, align, colspan, rowspan }) => {
  return (
    <TableData align={align} colspan={colspan} rowspan={rowspan}>
      {children}
    </TableData>
  );
};

const schema = {
  render: 'MarkdownTableData',
  attributes: {
    align: { type: String, required: false, default: 'left' },
    colspan: { type: Number, required: false, default: undefined },
    rowspan: { type: Number, required: false, default: undefined },
  },
};

const tableData: MarkdownNode = {
  node: {
    nodeType: 'td',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownTableData,
  },
};

export { tableData };
