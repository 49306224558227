import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { CodeField } from '@squareup/dex-ui-shared-base';
import React, { FC } from 'react';

import styles from './nodes.module.css';

interface MarkdownInlineCodeProps {
  content: string;
}

const MarkdownInlineCode: FC<MarkdownInlineCodeProps> = ({ content }) => {
  return (
    <CodeField
      as="code"
      className={styles['inline-code']}
      padding={{ horizontal: '0.5x' }}
      border={{ radius: '4' }}
    >
      {content}
    </CodeField>
  );
};

const schema = {
  render: 'MarkdownInlineCode',
  attributes: {
    content: { type: String, required: true, default: '' },
  },
};

const inlineCode: MarkdownNode = {
  node: {
    nodeType: 'code',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownInlineCode,
  },
};

export { inlineCode, MarkdownInlineCode };
