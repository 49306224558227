import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

const anchorRegex = /<a\s+(id|name)="(?<id>[\w-._]+)"\s*\/?>\s*(<\/a\s*>)?/g;
const anchorHrefRegex =
  /<a\s+href="(?<href>[\w-./:_]+)"\s*\/?>(?<content>[\w-/ .:_]+)(<\/a\s*>)?/g;

interface AnchorRegexGroup {
  id: string;
}

interface AnchorHrefRegexGroup {
  href: string;
  content: string;
}
/**
 * A transformer to convert <a id="<id>"></a> to {% anchor id="<id>" /%}
 */
const htmlAnchorSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content
        ?.replaceAll(anchorRegex, (str: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(str);
          }

          // Last arg is always the named group
          const groups: AnchorRegexGroup = args
            ? (args[args.length - 1] as AnchorRegexGroup)
            : { id: '' };

          const anchor = groups?.id?.trim() ? `id="${groups.id.trim()}"` : '';

          return `{% anchor ${anchor} /%}\n`;
        })
        ?.replaceAll(
          anchorHrefRegex,
          (original: string, ...args: unknown[]) => {
            if (highlight) {
              return highlightText(original);
            }

            // Last arg is always the named group
            const groups: AnchorHrefRegexGroup = args
              ? (args[args.length - 1] as AnchorHrefRegexGroup)
              : { href: '', content: '' };

            if (!groups.href) {
              return original;
            }

            return `[${groups.content}](${groups.href})`;
          }
        ) || ''
    );
  },
};

export { htmlAnchorSyntaxTransformer };
