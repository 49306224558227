import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { DocPageBanner } from '@squareup/dex-ui';
import dynamic from 'next/dynamic';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownBannerProps {
  imagePath?: string | undefined;
  imageAlt?: string | undefined;
  capability?:
    | 'payments'
    | 'commerce'
    | 'merchants'
    | 'staff'
    | 'customers'
    | undefined;
}

// Lazy load the animations because they're quite large
const CapabilityAnimation = dynamic(() =>
  import('./MarkdownBannerCapabilityAnimation').then((mod) => mod.default)
);

const MarkdownBanner: FC<PropsWithChildren<MarkdownBannerProps>> = ({
  children,
  imagePath,
  imageAlt,
  capability,
}) => {
  const capabilityAnimation = capability ? (
    <CapabilityAnimation capability={capability} />
  ) : null;

  return (
    <DocPageBanner
      imagePath={imagePath}
      imageAlt={imageAlt}
      customImage={capabilityAnimation}
    >
      {children}
    </DocPageBanner>
  );
};

const schema = {
  render: 'MarkdownBanner',
  attributes: {
    imagePath: { type: String, required: true },
    imageAlt: { type: String, required: true },
    capability: { type: String, required: false },
  },
};

const banner: MarkdownComponent = {
  tag: {
    name: 'banner',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownBanner,
  },
};

export { banner };
