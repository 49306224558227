import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

const youtubeRegex = /<iframe src="(?<src>[\w./:]+)".*<\/iframe>/g;
interface YoutubeRegexGroup {
  src?: string;
}

/**
 * Youtube embeds were implemented as iframes in the markdown content.
 * To handle it for now, we'll convert the iframes into {% youtube %}
 * components
 */
const youtubeSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replaceAll(
        youtubeRegex,
        (original: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(original);
          }

          // Last arg is always the named group
          const groups: YoutubeRegexGroup = args
            ? (args[args.length - 1] as YoutubeRegexGroup)
            : { src: '' };

          const src = groups.src ? `src="${groups.src}"` : '';

          return `{% youtube ${src} /%}`;
        }
      ) || ''
    );
  },
};

export { youtubeSyntaxTransformer };
