import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box, Heading5 } from '@squareup/dex-ui-shared-base';
import { MarkdownHeading } from '@squareup/dex-ui-shared-markdown-components';
import { TableOfContents } from '@squareup/dex-ui-shared-table-of-contents';
import React, { FC, useContext } from 'react';

import { MarkdownTocContextInstance } from './MarkdownTocProvider';
import styles from './markdown-toc.module.css';

interface MarkdownTocProps {
  depth: number;
  hide: boolean;
  disabled?: boolean | undefined;
}

/**
 * A wrapper of the DesktopToc and NarrowToc components. It controls
 * when to show and hide the components from Markdown.
 * If `hide=true`, then we want to show both the narrow and desktop TOCs
 * at their respective widths. If `hide=false`, then that actually means to
 * hide only the desktop TOC. In other words, the narrow TOC is always visible!
 */
const MarkdownToc: FC<MarkdownTocProps> = ({
  hide,
  depth,
  disabled = false,
}) => {
  const tocContext = useContext(MarkdownTocContextInstance);
  const desktopToc = (
    <Box className={styles.desktop} data-testid={'toc-large'}>
      <TableOfContents
        heading={
          <MarkdownHeading level={2} disableAnchor={true}>
            On this page
          </MarkdownHeading>
        }
        sections={tocContext.sections.filter(
          (section) => section.level <= depth
        )}
        narrow={false}
      ></TableOfContents>
    </Box>
  );

  const narrowToc = (
    <Box
      className={styles.narrow}
      margin={{ top: '3x', bottom: '6x' }}
      data-testid={'toc-narrow'}
    >
      <TableOfContents
        heading={<Heading5 padding={{ vertical: '1x' }}>On this page</Heading5>}
        // H3s don't belong in the narrow TOC
        sections={tocContext.sections.filter((section) => section.level < 3)}
        narrow={true}
      ></TableOfContents>
    </Box>
  );

  return (
    <>
      {!hide && !disabled && desktopToc}
      {!disabled && narrowToc}
    </>
  );
};

const schema = {
  render: 'MarkdownToc',
  children: ['inline'],
  attributes: {
    depth: { type: Number, required: false, default: 2 },
    hide: { type: Boolean, required: false, default: false },
    disabled: { type: Boolean, required: false, default: false },
  },
  selfClosing: true,
};

const toc: MarkdownComponent = {
  tag: {
    name: 'toc',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownToc,
  },
};

export { toc, MarkdownToc, type MarkdownTocProps };
