import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { DocBadge } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

import { useMarkdownCardContext } from './MarkdownCardProvider';
import styles from './markdown-card.module.css';

interface MarkdownCardBadgeProps {
  href?: string;
}

const MarkdownCardBadge: FC<PropsWithChildren<MarkdownCardBadgeProps>> = ({
  children,
  href,
}) => {
  const { inCard } = useMarkdownCardContext();

  return inCard ? (
    <DocBadge
      href={href}
      trackingId={'doc-card-badge'}
      className={styles.badge}
    >
      {children}
    </DocBadge>
  ) : null;
};

const schema = {
  render: 'MarkdownCardBadge',
  attributes: {
    href: { type: String, required: false },
  },
};

const cardBadge: MarkdownComponent = {
  tag: {
    name: 'card-badge',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCardBadge,
  },
};

export { cardBadge, MarkdownCardBadge };
