import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Paragraph30 } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-html-highlighter.module.css';

interface MarkdownHtmlHighlighterProps {}

const MarkdownHtmlHighlighter: FC<
  PropsWithChildren<MarkdownHtmlHighlighterProps>
> = ({ children }) => {
  return (
    <Paragraph30
      as="a"
      {...{
        href: '/dev/guides/docs/markdown/migration',
        target: '_blank',
      }}
      data-html-highlighter
      className={styles.highlighter}
      padding={{ vertical: '2x', horizontal: '2x' }}
      border={{ line: '20', radius: '6' }}
      weight="semi-bold"
    >
      {children}
    </Paragraph30>
  );
};

const schema = {
  render: 'MarkdownHtmlHighlighter',
  attributes: {},
};

const htmlHighlighter: MarkdownComponent = {
  tag: {
    name: 'html-highlighter',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownHtmlHighlighter,
  },
};

export { htmlHighlighter };
