import {
  MarkdownContext,
  useMarkdownContext,
} from '@squareup/dex-markdown-context-provider';
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './nodes.module.css';

interface MarkdownLinkProps {
  href: string;
}

const EmptyLinkWrapper: Exclude<MarkdownContext['LinkComponent'], null> = ({
  children,
}) => <>{children}</>;

// TODO jguze: How to inject the next.js Link component here?
const MarkdownLink: FC<
  PropsWithChildren<MarkdownLinkProps & Partial<TrackedProps>>
> = ({ children, href, trackingId }) => {
  const markdownContext = useMarkdownContext();
  const LinkWrapper = markdownContext.LinkComponent || EmptyLinkWrapper;
  const LinkPreviewer = markdownContext.LinkPreviewer;

  // Remove the trailing / because next/link will replace it anyways
  // This way the SSR HTML will match
  const processedHref = markdownContext.linkRewriter(
    href?.length > 1 ? href?.replace(/\/$/, '') : href
  );

  const anchor = (
    <LinkWrapper
      href={processedHref}
      trackingId={trackingId || 'markdown-link'}
    >
      <Box
        as="a"
        className={styles['markdown-link']}
        trackingId={trackingId || 'markdown-link'}
      >
        {children}
      </Box>
    </LinkWrapper>
  );

  return LinkPreviewer ? <LinkPreviewer>{anchor}</LinkPreviewer> : anchor;
};

const schema = {
  render: 'MarkdownLink',
  children: ['inline'],
  attributes: {
    href: {
      type: String,
      required: true,
      errorLevel: 'critical' as 'critical',
      default: '',
    },
    title: { type: String, required: false },
  },
};

const link: MarkdownNode = {
  node: {
    nodeType: 'link',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownLink,
  },
};

export { link, MarkdownLink };
