import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { DocCardLinkOut } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

import { useMarkdownCardContext } from './MarkdownCardProvider';
import styles from './markdown-card.module.css';

interface MarkdownCardLinkOutProps {
  href?: string | undefined;
}

const MarkdownCardLinkOut: FC<PropsWithChildren<MarkdownCardLinkOutProps>> = ({
  children,
  href,
}) => {
  const { href: cardHref } = useMarkdownCardContext();

  return (
    <DocCardLinkOut
      className={styles['card-link-out']}
      href={cardHref || href || undefined}
    >
      {children}
    </DocCardLinkOut>
  );
};

const schema = {
  render: 'MarkdownCardLinkOut',
  attributes: {
    href: {
      type: String,
      required: false,
    },
  },
};

const cardLinkOut: MarkdownComponent = {
  tag: {
    name: 'card-link-out',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCardLinkOut,
  },
};

export { cardLinkOut, MarkdownCardLinkOut };
