import { Left, Right } from '@squareup/dex-icons/market/arrow';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { isScrollableX } from '@squareup/dex-utils-dom';
import clsx from 'clsx';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { observe } from 'react-intersection-observer';

import { NativeButton } from '../NativeButton';
import { shimStyles } from '../Shim';

import styles from './carousel.module.css';

interface CarouselButtonProps {
  direction: 'left' | 'right';
  onClick?: () => void;
}

const CarouselButton: FC<CarouselButtonProps> = ({ direction, onClick }) => {
  return (
    <Box
      onClick={onClick}
      className={clsx(styles['carousel-button-container'], styles[direction])}
    >
      <NativeButton
        aria-label={
          direction === 'left' ? 'Shift carousel left' : 'Shift carousel right'
        }
        className={styles['carousel-button']}
        trackingId={`carousel-button-${direction}`}
        padding={{ vertical: '1.5x', horizontal: '1.5x' }}
      >
        {direction === 'left' ? (
          <Left className={commonIconStyles['icon-fill-current-color']} />
        ) : (
          <Right className={commonIconStyles['icon-fill-current-color']} />
        )}
      </NativeButton>
    </Box>
  );
};

interface CarouselProps {}

const CARD_WIDTH = 256;

const Carousel: FC<
  PropsWithChildren<
    CarouselProps &
      NullableClassName &
      Pick<BoxProps, 'margin' | 'padding' | 'border'>
  >
> = ({ children, className, ...rest }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(false);

  useEffect(() => {
    if (!carouselRef.current) {
      return () => null;
    }

    // If there's not enough room to scroll, don't do anything
    if (!isScrollableX(carouselRef.current)) {
      return () => null;
    }

    // Grab first and last items in the card
    const cards = carouselRef.current.children;
    const firstCard = cards[0] as HTMLElement;
    const lastCard = cards[cards.length - 1] as HTMLElement;

    const firstObserverCleanup = observe(
      firstCard,
      (inView) => {
        if (inView) {
          setShowLeftButton(false);
        } else {
          setShowLeftButton(true);
        }
      },
      // We want to hide the left button when the first card is 5% out of view view
      { rootMargin: '50px 0px 50px 95%', threshold: 1 }
    );

    const lastObserverCleanup = observe(
      lastCard,
      (inView) => {
        if (inView) {
          setShowRightButton(false);
        } else {
          setShowRightButton(true);
        }
      },
      // We want to hide the right button when the last card is 5% out of view view
      { rootMargin: '50px 95% 50px 0px', threshold: 1 }
    );

    return () => {
      firstObserverCleanup();
      lastObserverCleanup();
    };
  });

  const onLeftClick = useCallback(() => {
    if (!carouselRef.current) {
      return;
    }

    const left = Math.max(0, carouselRef.current.scrollLeft - CARD_WIDTH);
    carouselRef.current.scrollTo({
      left,
      behavior: 'smooth',
    });
  }, []);

  const onRightClick = useCallback(() => {
    if (!carouselRef.current) {
      return;
    }

    const left = Math.min(
      carouselRef.current.scrollLeft + CARD_WIDTH,
      carouselRef.current.scrollWidth
    );
    carouselRef.current.scrollTo({
      left,
      behavior: 'smooth',
    });
  }, []);

  return (
    <Box
      testId="carousel"
      className={clsx(
        styles.container,
        styles.shims,
        shimStyles.setup,
        shimStyles.light,
        showLeftButton && shimStyles['shim-horizontal-left'],
        showRightButton && shimStyles['shim-horizontal-right'],
        className
      )}
      {...rest}
    >
      {showLeftButton && (
        <CarouselButton direction="left" onClick={onLeftClick} />
      )}
      <Box className={styles.carousel} ref={carouselRef}>
        {children}
      </Box>
      {showRightButton && (
        <CarouselButton direction="right" onClick={onRightClick} />
      )}
    </Box>
  );
};

export { Carousel };
