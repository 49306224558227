import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import {
  card,
  cardBadge,
  cardImageOffset,
  cardLayout,
  cardLinkOut,
} from '@squareup/dex-ui-shared-markdown-card';
import {
  accordion,
  anchor,
  aside,
  banner,
  carousel,
  carouselCard,
  docsCta,
  grid,
  htmlHighlighter,
  iconContainer,
  lineBreak,
  markdownButtonGroup,
  resourceInfo,
  resourceInfoLayout,
  subheading,
  tab,
  tabset,
  tooltip,
  youtube,
} from '@squareup/dex-ui-shared-markdown-components';
import { layout } from '@squareup/dex-ui-shared-markdown-layout';
import { toc } from '@squareup/dex-ui-shared-markdown-toc';

const components: Array<MarkdownComponent> = [
  toc,
  aside,
  tooltip,
  anchor,
  subheading,
  docsCta,
  layout,
  card,
  cardLinkOut,
  cardBadge,
  cardLayout,
  cardImageOffset,
  resourceInfo,
  resourceInfoLayout,
  banner,
  accordion,
  lineBreak,
  youtube,
  htmlHighlighter,
  carousel,
  carouselCard,
  tab,
  tabset,
  iconContainer,
  grid,
  markdownButtonGroup,
];
export { components };
