import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { NativeButton } from '../NativeButton';

import styles from './code-block-footer.module.css';

interface CodeBlockFooterButtonProps {
  onClick?: () => void;
  round?: boolean;
}

interface CodeBlockFooterLinkProps {
  href: string;
  target?: '_self' | '_blank' | '_parent' | '_top';
}

const CodeBlockFooterButton: FC<
  PropsWithChildren<
    CodeBlockFooterButtonProps & NullableClassName & TestProps & TrackedProps
  >
> = ({ children, className, trackingId, testId, onClick, round = false }) => {
  return (
    <NativeButton
      className={clsx(styles.button, round && styles.round, className)}
      border={round ? undefined : { radius: 'standard' }}
      padding={round ? undefined : { horizontal: '1.5x', vertical: '1x' }}
      trackingId={trackingId}
      testId={testId}
      onClick={onClick}
    >
      {children}
    </NativeButton>
  );
};

const CodeBlockFooterLink: FC<
  PropsWithChildren<
    CodeBlockFooterLinkProps & NullableClassName & TestProps & TrackedProps
  >
> = ({ children, className, trackingId, testId, href, target }) => {
  return (
    <Box
      as="a"
      border={{ radius: 'standard' }}
      padding={{ horizontal: '1.5x', vertical: '1x' }}
      href={href}
      target={target || '_self'}
      className={clsx(styles.button, styles.link, className)}
      trackingId={trackingId}
      testId={testId}
    >
      {children}
    </Box>
  );
};

export { CodeBlockFooterButton, CodeBlockFooterLink };
