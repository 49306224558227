import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { MarkdownLayout } from '@squareup/dex-ui-shared-markdown-layout';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-resource-info.module.css';

interface MarkdownResourceInfoLayoutProps {}

const MarkdownResourceInfoLayout: FC<
  PropsWithChildren<MarkdownResourceInfoLayoutProps>
> = ({ children }) => {
  const largeLayout = (
    <MarkdownLayout
      direction="row"
      justify="space-between"
      spaceAbove="4x"
      hideWhen="mobile"
    >
      {children}
    </MarkdownLayout>
  );

  const narrowLayout = (
    <MarkdownLayout
      className={clsx(styles.grid, styles['layout-narrow'])}
      direction="row"
      justify="start"
      spaceAbove="4x"
      hideWhen="desktop"
      type="grid"
    >
      {children}
    </MarkdownLayout>
  );

  return (
    <>
      {largeLayout}
      {narrowLayout}
    </>
  );
};

const schema = {
  render: 'MarkdownResourceInfoLayout',
};

const resourceInfoLayout: MarkdownComponent = {
  tag: {
    name: 'resource-info-layout',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownResourceInfoLayout,
  },
};

export { resourceInfoLayout };
