import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

const divRegexWithWidth =
  /<div\s*(style="?width: *(?<width>[\d a-z]+)[\s,]*(text-align: *(?<align>[a-z]+))?[\s\w#:-]*"?)?\s*>(?<content>[\d A-z-]*)?<\/div[\s"=A-Za-z-]*>/g;
interface DivRegexGroup {
  width?: string;
  align?: string;
  content: string;
}

/**
 * The main area this is used is as part of HTML tables.
 * Writers used divs to set an inline `width` style to set the width
 * of the table headers.
 * However, markdoc has support for setting the width as a markdoc attribute.
 * Therefore, we will take the accepted styles (width, text-align), and
 * pass them as markdoc attributes.
 */
const divSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replaceAll(
        divRegexWithWidth,
        (str: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(str);
          }

          // Last arg is always the named group
          const groups: DivRegexGroup = args
            ? (args[args.length - 1] as DivRegexGroup)
            : { content: '' };

          let attrs = '';
          if (groups.width || groups.align) {
            const width = groups.width ? `width="${groups.width}"` : '';
            const align = groups.align ? ` align="${groups.align}"` : '';
            attrs = ` {% ${width}${align} %}`;
          }

          return `${groups.content || ''}${attrs}`;
        }
      ) || ''
    );
  },
};

export { divSyntaxTransformer };
