/* eslint-disable react/display-name */
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { AnchoredHeading } from '@squareup/dex-ui';
import {
  Display10,
  Heading10,
  Heading20,
  Heading30,
  Box,
  BoxProps,
} from '@squareup/dex-ui-shared-base';
import { useMarkdownCardContext } from '@squareup/dex-ui-shared-markdown-card';
import { toMarkdownHeadingHrefHash } from '@squareup/dex-utils-shared-markdown-helpers';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './nodes.module.css';

interface HeadingProps {
  level: Number;
  disableAnchor?: boolean;
  emphasis?: boolean;
  noMargin?: boolean;
}

const MarkdownHeading: FC<PropsWithChildren<HeadingProps>> = ({
  level,
  disableAnchor = false,
  children,
  emphasis,
  noMargin = false,
}) => {
  let Result: FC<Pick<BoxProps, 'margin'>>;
  let margin = {};
  const className = clsx(emphasis && styles.emphasis);
  if (level === 1) {
    Result = ({ margin }) => (
      <Display10 className={className} margin={margin}>
        {children}
      </Display10>
    );
  } else if (level === 2) {
    Result = ({ margin }) => (
      <Heading30 className={className} margin={margin}>
        {children}
      </Heading30>
    );
    margin = { top: '5x', bottom: '2x' };
  } else if (level === 3) {
    Result = ({ margin }) => (
      <Heading20 className={className} margin={margin}>
        {children}
      </Heading20>
    );
    margin = { top: '3x', bottom: '2x' };
  } else {
    Result = ({ margin }) => (
      <Heading10 className={className} margin={margin}>
        {children}
      </Heading10>
    );
    margin = { top: '3x', bottom: '0.5x' };
  }

  Result.displayName = 'MarkdownHeadingResult';
  if (noMargin) {
    margin = {};
  }

  const { inCard } = useMarkdownCardContext();

  if (inCard) {
    return <Result />;
  } else if (typeof children === 'string' && !disableAnchor) {
    // Make the heading an anchor
    return (
      <Box margin={margin} data-markdown-heading={level}>
        <AnchoredHeading
          anchor={toMarkdownHeadingHrefHash(children)}
          trackingId={'heading-anchor'}
        >
          <Result />
        </AnchoredHeading>
      </Box>
    );
  }

  return (
    <Box margin={margin} data-markdown-heading={level}>
      <Result />
    </Box>
  );
};

const schema = {
  render: 'MarkdownHeading',
  children: ['inline'],
  attributes: {
    level: { type: Number, required: true, default: 1 },
    disableAnchor: { type: Boolean, required: false, default: false },
    emphasis: { type: Boolean, required: false, default: false },
    noMargin: { type: Boolean, required: false, default: false },
  },
};

const heading: MarkdownNode = {
  node: {
    nodeType: 'heading',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownHeading,
  },
};

export { heading, MarkdownHeading };
