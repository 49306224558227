/* eslint-disable no-param-reassign */
import { Config, Node, Tag } from '@markdoc/markdoc';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import {
  MarkdownLayout,
  MarkdownLayoutProps,
} from '@squareup/dex-ui-shared-markdown-layout';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { card } from './MarkdownCard';
import { MarkdownCardLayoutContextProvider } from './MarkdownCardLayoutProvider';
import styles from './markdown-card.module.css';

interface MarkdownCardLayoutProps extends MarkdownLayoutProps {
  // With card layouts, if you choose a grid type, you default to having start justification,
  // and no margins between cards, and borderless cards
  type?: 'grid' | 'flex' | undefined;
  // Cards become simple containers rather than actual links. Therefore you must add other links
  // inside the card to make it linkable. This still allows you to pass an href to the card to control the link out,
  // but the card itself is not an anchor
  disableCardLink?: boolean | undefined;
}

const MarkdownCardLayout: FC<PropsWithChildren<MarkdownCardLayoutProps>> = ({
  children,
  spaceAbove,
  justify = 'space-between',
  direction = 'row',
  wrap = false,
  type,
}) => {
  if (type === 'grid') {
    direction = 'row';
    justify = 'start';
    spaceAbove = '2x';
  }

  if (!spaceAbove) {
    spaceAbove = '4x';
  }

  const largeLayout = (
    <MarkdownCardLayoutContextProvider value={{ isNarrowLayout: false }}>
      <MarkdownLayout
        testId="card-layout-desktop"
        className={clsx(
          styles['card-layout'],
          type === 'grid' && styles.grid,
          styles[direction],
          wrap && styles.wrap
        )}
        spaceAbove={spaceAbove}
        justify={justify}
        direction={direction}
        type={type}
        wrap={wrap}
        hideWhen="mobile"
      >
        {children}
      </MarkdownLayout>
    </MarkdownCardLayoutContextProvider>
  );

  const narrowLayout = (
    <MarkdownCardLayoutContextProvider value={{ isNarrowLayout: true }}>
      <MarkdownLayout
        testId="card-layout-narrow"
        className={clsx(
          styles['card-layout'],
          type === 'grid' && styles.grid,
          styles.col,
          wrap && styles.wrap
        )}
        spaceAbove={spaceAbove}
        justify={justify}
        direction={'col'}
        type={type}
        wrap={wrap}
        hideWhen="desktop"
      >
        {children}
      </MarkdownLayout>
    </MarkdownCardLayoutContextProvider>
  );

  return (
    <>
      {largeLayout}
      {narrowLayout}
    </>
  );
};

const schema = {
  render: 'MarkdownCardLayout',
  children: ['inline'],
  attributes: {
    direction: { type: String, required: false },
    justify: { type: String, required: false },
    spaceAbove: { type: String, required: false },
    wrap: { type: Boolean, required: false },
    type: { type: String, required: false },
    disableCardLink: { type: String, required: false },
  },
  transform(node: Node, config: Config) {
    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    // Cards in this type of layout need to be borderless, darken hovers, etc
    children
      .filter(
        (child): child is Tag =>
          Tag.isTag(child) && child.name === card.component.tagName
      )
      .forEach((child) => {
        if (attributes.type === 'grid') {
          child.attributes = {
            ...child.attributes,
            forceBorderless: true,
            paddingSize: 'small',
            hoverStyle: 'darken',
          };
        }

        if (attributes.disableCardLink) {
          child.attributes = {
            ...child.attributes,
            disableCardHref: true,
          };
        }
      });

    return new Tag(this.render, attributes, children);
  },
};

const cardLayout: MarkdownComponent = {
  tag: {
    name: 'card-layout',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCardLayout,
  },
};

export { cardLayout, MarkdownCardLayout };
