import * as React from 'react';
import { SVGProps } from 'react';

const SvgRight = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={13}
    height={14}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.086 6 4.793 1.707 6.207.293l6 6a1 1 0 0 1 0 1.414l-6 6-1.414-1.414L9.086 8H0V6h9.086Z"
      fill="#005AD9"
    />
  </svg>
);
export default SvgRight;
