import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import Image from 'next/image';
import React, { FC, PropsWithChildren } from 'react';

import styles from './icon-container.module.css';

interface IconContainerProps {
  iconPath: string;
  iconAlt?: string | undefined;
  iconWidth?: number | undefined;
  iconHeight?: number | undefined;
}

/**
 * A simple container that places an icon at the leading edge
 * Useful when combined with a link
 */
const IconContainer: FC<
  PropsWithChildren<
    IconContainerProps & Pick<BoxProps, 'margin' | 'padding' | 'border'>
  >
> = ({
  children,
  iconPath,
  iconAlt,
  iconWidth = 24,
  iconHeight = 24,
  ...rest
}) => {
  return (
    <Box className={styles['icon-container']} {...rest}>
      <Image
        src={iconPath}
        alt={iconAlt || 'icon'}
        width={iconWidth}
        height={iconHeight}
      />
      {children}
    </Box>
  );
};

export { IconContainer };
