import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';
import {
  getNextPublicBaseURL,
  getNextPublicSquareURL,
} from '@squareup/dex-utils-environment';

const specialConstantsWithDeveloperSubdomainTransformer =
  /(https:\/\/developer\.{{SQUARE_DOMAIN}})\s*/g;
const specialConstantsTransformer = /\s*{{SQUARE_DOMAIN}}\s*/g;

const regexToReplacement = [
  {
    regex: specialConstantsWithDeveloperSubdomainTransformer,
    replacement: getNextPublicBaseURL(),
  },
  {
    regex: specialConstantsTransformer,
    replacement: getNextPublicSquareURL()
      ? new URL(getNextPublicSquareURL())?.host
      : '',
  },
];

/**
 * The only point of this is to take constants that aren't actually
 * done as a Contentful constant set, and transform them into a
 * pre-planned variables. The only real special constant is {{SQUARE_DOMAIN}},
 * which we will handle here.
 */
const specialConstantsSyntaxTransfomers: MarkdownSyntaxTransformer = {
  transform(content: string | undefined): string {
    if (!content) {
      return '';
    }

    let result = content;
    for (const { regex, replacement } of regexToReplacement) {
      result = result?.replaceAll(regex, replacement);
    }

    return result;
  },
};

export { specialConstantsSyntaxTransfomers };
