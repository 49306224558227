import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps, TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import { Image } from '@squareup/dex-ui-shared-image';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { focusRingStyles } from '../FocusRing';
import { Link } from '../Link';

import styles from './doc-card.module.css';

interface DocCardProps {
  // The path to the icon, if present
  iconPath?: string | undefined;
  // The alt of the icon image, if present
  iconAlt?: string | undefined;
  // The width of the icon
  iconWidth?: number | undefined;
  // The height of the icon
  iconHeight?: number | undefined;
  // Whether the icon has a border
  iconBorder?: boolean | undefined;
  // Determines where the card will link to when clicked
  href?: string | undefined;
  // Determines if the card has a border or not
  borderless?: boolean | undefined;
  // Determines the size of the padding for the card. By default, it's 3x, but it can either be none, or 2x
  paddingSize?: 'small' | 'medium' | 'none' | undefined;
  // Defines if the card hover style is a box-shadow, or if the card bg darkens
  hoverStyle?: 'shadow' | 'darken' | 'none' | undefined;
  // The descriptive aria-label for the link
  linkLabel?: string | undefined;
}

const LinkWrapper: FC<
  PropsWithChildren<DocCardProps & NullableClassName & TrackedProps>
> = ({ children, href, trackingId }) => {
  return href ? (
    <Link passHref omitAnchor={true} href={href} trackingId={trackingId}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

const DocCard: FC<
  PropsWithChildren<DocCardProps & NullableClassName & TrackedProps & TestProps>
> = ({
  children,
  className,
  iconPath,
  iconAlt,
  iconWidth = 24,
  iconHeight = 24,
  href,
  trackingId,
  testId,
  borderless = false,
  paddingSize = 'medium',
  hoverStyle = 'shadow',
  iconBorder = 'false',
  linkLabel,
}) => {
  const paddingLength =
    paddingSize === 'small'
      ? '2x'
      : paddingSize === 'medium'
      ? '3x'
      : undefined;

  return (
    <Box
      className={clsx(
        styles['doc-card'],
        !borderless && styles.border,
        // While borderless doesn't really mean it's in the grid,
        // it's a good enough proxy for now. We can add an explicit prop
        // later if this becomes a larger styling issue
        borderless && styles['in-grid'],
        hoverStyle && hoverStyle !== 'none' && href && styles[hoverStyle],
        paddingSize === 'small' && styles.small,
        className
      )}
      testId={testId}
      border={{ radius: '6', line: borderless ? undefined : 'standard' }}
      padding={
        paddingLength
          ? { vertical: paddingLength, horizontal: paddingLength }
          : undefined
      }
    >
      <LinkWrapper href={href} trackingId={trackingId}>
        {href && (
          <Box
            testId="markdown-card-anchor"
            aria-label={linkLabel}
            as="a"
            className={clsx(
              styles['doc-card-anchor'],
              focusRingStyles['focus-ring']
            )}
          />
        )}
      </LinkWrapper>
      <Box className={clsx(styles.content, iconBorder && styles.border)}>
        {iconPath && (
          <Image
            src={iconPath}
            alt={iconAlt || 'icon'}
            width={iconWidth}
            height={iconHeight}
          />
        )}
        {children}
      </Box>
    </Box>
  );
};

export { DocCard };
