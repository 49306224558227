import Down from '@squareup/dex-icons/dex/arrow/Down';
import Up from '@squareup/dex-icons/dex/arrow/Up';
import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import {
  CodeBlock,
  CodeBlockButtonActionGroup,
  CodeBlockFooterButton,
  CodeBlockFooterLink,
  CodeCloseButton,
  CodeCopyButton,
  CodeExpandButton,
} from '@squareup/dex-ui';
import { Box, Paragraph10 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import { ModalWideContent } from '@squareup/dex-ui-shared-market';
import { HighlightLanguage } from '@squareup/dex-utils-highlight';
import clsx from 'clsx';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useState,
} from 'react';

import styles from './markdown-code-block.module.css';

interface MarkdownCodeBlockProps {
  content: string;
  language: HighlightLanguage;
  floatingHeader?: boolean | undefined;
  title?: ReactNode;
  actionGroupBlock?: ReactNode;
  footerBlock?: ReactNode;
  isLoading?: boolean | undefined;
  lineNumbers?: boolean;
  openInEditor?: boolean;
  isRequestStyle?: boolean;
}

const MAX_LINES_BEFORE_COLLAPSING = 15;

const MarkdownCodeBlock: FC<PropsWithChildren<MarkdownCodeBlockProps>> = ({
  content,
  language,
  floatingHeader,
  actionGroupBlock,
  footerBlock,
  title,
  isLoading = false,
  lineNumbers = true,
  openInEditor = false,
  isRequestStyle = true,
}) => {
  // Intentionally remove the extra newline at the end
  let code = content.trimEnd();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBottomExpanded, setIsBottomExpanded] = useState(false);

  const lines = (code?.split('\n').length || 1) - 1;
  const showBottomBar = lines > MAX_LINES_BEFORE_COLLAPSING;

  const showLineNumbers = lines === 0 ? false : lineNumbers;
  const showExpandButton = lines > 0;

  if ((openInEditor || footerBlock) && lines < 3) {
    code += '\n\n';
  }

  const onBottomBarExpandClicked = useCallback(() => {
    setIsBottomExpanded(!isBottomExpanded);
  }, [isBottomExpanded, setIsBottomExpanded]);

  const editorButton = openInEditor && (
    <CodeBlockFooterLink
      href={`/dev/guides/docs/editor?markdown=${btoa(
        encodeURIComponent(content)
      )}`}
      trackingId={'open-in-editor-link'}
      testId={'open-in-editor-link'}
      target="_blank"
    >
      <Paragraph10 weight={'semi-bold'}>Open in editor</Paragraph10>
    </CodeBlockFooterLink>
  );

  const footerGroup = (
    <Box className={styles['bottom-bar']}>
      {showBottomBar && (
        <CodeBlockFooterButton
          className={clsx(styles['bar-position'])}
          round={true}
          trackingId={'expand-bottom-code-block'}
          testId={'expand-bottom-code-block'}
          onClick={onBottomBarExpandClicked}
        >
          {isBottomExpanded ? (
            <Up className={commonIconStyles['icon-color']} />
          ) : (
            <Down className={commonIconStyles['icon-color']} />
          )}
        </CodeBlockFooterButton>
      )}
      <Box className={clsx(styles['bar-position'], styles['footer-action'])}>
        {editorButton || footerBlock}
      </Box>
    </Box>
  );

  const onExpandClicked = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);

  const closeModal = useCallback(() => {
    setIsModalOpen(false);
  }, [setIsModalOpen]);

  const CodeBlockModal = isModalOpen && (
    <ModalWideContent
      noVerticalPadding={true}
      transparentBg={true}
      paddedSides={true}
      onMarketDialogDismissed={closeModal}
    >
      <CodeBlock
        title={title}
        isRequest={isRequestStyle}
        showLineNumbers={lineNumbers}
        isFloatingHeader={floatingHeader}
        actionButtonGroup={
          <CodeBlockButtonActionGroup>
            {actionGroupBlock}
            <CodeCopyButton code={content} />
            <CodeCloseButton onClick={closeModal} />
          </CodeBlockButtonActionGroup>
        }
        code={code}
        language={language}
      />
    </ModalWideContent>
  );

  return (
    <>
      <CodeBlock
        className={clsx(!isBottomExpanded && styles['limit-height'])}
        preClassName={clsx(isLoading && styles.loading)}
        isLoading={isLoading}
        isRequest={isRequestStyle}
        showLineNumbers={showLineNumbers}
        isFloatingHeader={floatingHeader}
        actionButtonGroup={
          <CodeBlockButtonActionGroup>
            {actionGroupBlock}
            <CodeCopyButton code={content} />
            {showExpandButton && <CodeExpandButton onClick={onExpandClicked} />}
          </CodeBlockButtonActionGroup>
        }
        title={title}
        code={code}
        language={language}
        headerBoxProps={{ margin: { top: '3x' } }}
        margin={{ bottom: '3x' }}
        footerButtonGroup={footerGroup}
        data-test-expanded={showBottomBar ? isBottomExpanded : undefined}
        aria-expanded={showBottomBar ? isBottomExpanded : undefined}
      ></CodeBlock>
      {CodeBlockModal}
    </>
  );
};

interface MarkdownBasicFenceProps {
  content: string;
  language: HighlightLanguage | string;
  lineNumbers?: boolean;
  openInEditor?: boolean;
}

const MarkdownBasicFence: FC<PropsWithChildren<MarkdownBasicFenceProps>> = ({
  content,
  language,
  lineNumbers = true,
  openInEditor = false,
}) => {
  return (
    <MarkdownCodeBlock
      content={content}
      language={language as HighlightLanguage}
      lineNumbers={lineNumbers}
      floatingHeader={true}
      openInEditor={openInEditor}
    />
  );
};

const schema = {
  render: 'MarkdownBasicFence',
  attributes: {
    content: { type: String, required: true, default: '' },
    language: { type: String, required: false, default: 'bash' },
    lineNumbers: { type: Boolean, required: false, default: true },
    openInEditor: { type: Boolean, required: false, default: false },
  },
};

const basicFence: MarkdownNode = {
  node: {
    nodeType: 'fence',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownBasicFence,
  },
};

export {
  MarkdownCodeBlock,
  basicFence,
  schema as basicFenceSchema,
  MarkdownBasicFence,
};
