import { TocSection } from '@squareup/dex-types-shared-docs';
import React, { FC, ReactNode } from 'react';

import { LargeTableOfContents } from './LargeTableOfContents';
import { NarrowTableOfContents } from './NarrowTableOfContents';

interface TableOfContentsProps {
  sections: Array<TocSection>;
  heading?: ReactNode;
  narrow?: boolean;
}

const TableOfContents: FC<TableOfContentsProps> = ({
  sections,
  heading,
  narrow,
}) => {
  if (sections.length === 0) {
    return null;
  }

  if (narrow) {
    return (
      <NarrowTableOfContents
        sections={sections}
        heading={heading}
        defaultExpanded={true}
      />
    );
  } else {
    return <LargeTableOfContents sections={sections} heading={heading} />;
  }
};

export { TableOfContents };
