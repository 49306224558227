import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-internal-grid.module.css';

interface MarkdownGridProps {
  dataName?: string | undefined;
}

const MarkdownGrid: FC<PropsWithChildren<MarkdownGridProps>> = ({
  children,
  dataName,
}) => {
  return (
    <Box
      margin={{ top: '2x' }}
      className={styles.grid}
      {...(dataName && { 'data-name': dataName })}
    >
      {children}
    </Box>
  );
};

const schema = {
  render: 'MarkdownGrid',
  attributes: {
    dataName: {
      type: String,
      default: '',
    },
  },
};

const grid: MarkdownComponent = {
  tag: {
    name: 'grid',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownGrid,
  },
};

export { grid };
