import NextImage from 'next/image';
import React, { ComponentProps, FC } from 'react';

import {
  contentfulImageLoader,
  convertContentfulImageSrc,
  isContentfulImageSrc,
} from './image-utils';

interface ImageProps {
  // If fill is false, this will set `fill` to true if the dimensions aren't passed in
  fillIfNoDimensions?: boolean;
}

type NextImageProps = Omit<ComponentProps<typeof NextImage>, 'loader'>;

/**
 * General wrapper component for next/image, allowing it to preprocess contentful image srcs as well
 * If it doesn't provide the right props for next/image, it will default back to a normal <img> tag
 * An important thing to note is Contentful image srcs may set the width or height of the image in
 * the url itself with `?w=100` or `?h=100` if they wanted the width of height to be 100 for example. But
 * we won't use that as the image dimensions, just the image we pull from contnetful.
 * Otherwise the quality of the image changes drastically.
 * NOTE: For any remote image src, we MUST set it up in the remotePatterns field in next.config.js.
 * See: https://nextjs.org/docs/pages/api-reference/components/image#remotepatterns
 */
const Image: FC<ImageProps & NextImageProps> = ({
  src,
  width,
  height,
  fill,
  fillIfNoDimensions,
  className,
  ...nextImageProps
}) => {
  const convertedSrc =
    typeof src === 'string' ? convertContentfulImageSrc(src) : src;
  const srcIsString = typeof convertedSrc === 'string';

  const finalFill =
    typeof fill === 'undefined' && typeof fillIfNoDimensions === 'undefined'
      ? undefined
      : Boolean(fill || (fillIfNoDimensions && !width && !height));

  // Can't use next/image if these props are all false
  if ((!width || !height) && !finalFill) {
    if (!srcIsString) {
      throw new TypeError(
        'cannot create an image with a StaticImport if width, height, and fill are not provided'
      );
    }

    return (
      <img
        className={className}
        src={convertedSrc}
        alt={nextImageProps.alt}
        title={nextImageProps.title}
        {...{
          width,
          height,
        }}
      />
    );
  }

  return (
    <NextImage
      className={className}
      loader={isContentfulImageSrc(src) ? contentfulImageLoader : undefined}
      src={convertedSrc}
      {...(isContentfulImageSrc(src) && { loader: contentfulImageLoader })}
      {...{
        width,
        height,
        fill: finalFill,
      }}
      {...nextImageProps}
    />
  );
};

export { Image };
