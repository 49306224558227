import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Paragraph30 } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-subheading.module.css';

interface MarkdownSubheadingProps {}

const MarkdownSubheading: FC<PropsWithChildren<MarkdownSubheadingProps>> = ({
  children,
}) => {
  return (
    <Paragraph30 as="span" className={styles.subheading} margin={{ top: '1x' }}>
      {children}
    </Paragraph30>
  );
};

const schema = {
  render: 'MarkdownSubheading',
};

const subheading: MarkdownComponent = {
  tag: {
    name: 'subheading',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownSubheading,
  },
};

export { subheading };
