import * as React from 'react';
import { SVGProps } from 'react';

const SvgUp = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.586 11 12 6.586 16.414 11 15 12.414l-2-2V17h-2v-6.586l-2 2L7.586 11Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgUp;
