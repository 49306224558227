import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC } from 'react';

import styles from './markdown-youtube-player.module.css';

interface MarkdownYoutubePlayerProps {
  src: string;
}

const MarkdownYoutubePlayer: FC<MarkdownYoutubePlayerProps> = ({ src }) => {
  return (
    <Box
      testId="youtube-player"
      margin={{ vertical: '3x' }}
      border={{ radius: '6' }}
      className={styles.container}
    >
      <iframe
        className={styles.iframe}
        src={src}
        title="Youtube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </Box>
  );
};

const schema = {
  render: 'MarkdownYoutubePlayer',
  attributes: {
    src: { type: String, required: true },
  },
};

const youtube: MarkdownComponent = {
  tag: {
    name: 'youtube',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownYoutubePlayer,
  },
};

export { youtube };
