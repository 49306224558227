import { Config, Node, Tag } from '@markdoc/markdoc';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Accordion } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

interface MarkdownAccordionProps {
  heading: ReactNode;
  expanded?: boolean;
}

/**
 * Usage:
 * ```markdown
 * {% accordion %}
 * {% slot "heading" %}
 * ## Heading goes here
 * {% /slot %}
 * All your content to be hidden goes here
 * {% /accordion %}
 * ```
 */
const MarkdownAccordion: FC<PropsWithChildren<MarkdownAccordionProps>> = ({
  heading,
  expanded = true,
  children,
}) => {
  return (
    <Accordion
      title={heading}
      defaultExpanded={expanded}
      trackingId={'markdown-accordion-clicked'}
      padding={{ vertical: '2x' }}
    >
      {children}
    </Accordion>
  );
};

const schema = {
  render: 'MarkdownAccordion',
  slots: {
    heading: {
      required: true,
    },
  },
  attributes: {
    expanded: {
      type: Boolean,
      required: false,
    },
  },
  transform(node: Node, config: Config) {
    const headingSlot = node.slots.heading?.children?.[0];
    if (headingSlot?.type === 'heading') {
      // Accordion headings have no margin
      headingSlot.attributes.noMargin = true;
    }

    const attributes = node.transformAttributes(config);
    const children = node.transformChildren(config);

    return new Tag(this.render, attributes, children);
  },
};

const accordion: MarkdownComponent = {
  tag: {
    name: 'accordion',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownAccordion,
  },
};

export { accordion };
