import { NullableClassName } from '@squareup/dex-types-shared-ui';
import clsx from 'clsx';
import React, { FunctionComponent, ReactNode } from 'react';

import { MarketModalPartial } from '../Market';

import styles from './modal.module.css';

type MarketModalPartialProps = React.ComponentProps<typeof MarketModalPartial>;

interface ModalWideContentProps {
  children: ReactNode;
  // Ensures there's no vertical padding on the modal
  noVerticalPadding?: boolean;
  // Forces the background of the modal itself to be transparent
  transparentBg?: boolean;
  // Gives the sides of the modal some space before the edge of the screen
  paddedSides?: boolean;
}

const ModalWideContent: FunctionComponent<
  ModalWideContentProps & NullableClassName & MarketModalPartialProps
> = ({
  children,
  className,
  noVerticalPadding = false,
  transparentBg = false,
  paddedSides = false,
  ...rest
}) => {
  return (
    <MarketModalPartial
      {...rest}
      className={clsx(
        styles['wide-content'],
        noVerticalPadding && styles['no-vert-padding'],
        transparentBg && styles['transparent-bg'],
        paddedSides && styles['padded-sides'],
        className
      )}
    >
      {children}
    </MarketModalPartial>
  );
};

export { ModalWideContent };
