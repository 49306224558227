import { Box } from '@squareup/dex-ui-shared-base';
import { MarketTooltip } from '@squareup/dex-ui-shared-market';
import clsx from 'clsx';
import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from 'react';

import styles from './tooltip.module.css';

interface TooltipProps {
  text: string;
}

const Tooltip: FC<PropsWithChildren<TooltipProps>> = ({ children, text }) => {
  const [isServer, setIsServer] = useState(true);
  const [isMarketTooltipLoaded, setIsMarketTooltipLoaded] = useState(false);

  useEffect(() => {
    setIsServer(false);
  }, []);

  const onMarketRef = useCallback(
    (node: globalThis.HTMLMarketTooltipElement) => {
      // Unfortunately, in an SSR world, we simply don't know when the
      // market component will be ready. So we'll use a 2 phased approach
      // 1. If not the server, never show the market-tooltip. The reason for this
      //    is because the slotted content won't appear, and nextjs will complain about hydration errors.
      //    You'll also see flashes of content
      // 2. If on the client, don't show the market-tooltip UNTIL it is hydrated. Web components are weird.
      //    They take time to hydrate, outside of the React render cycle. So we'll just poll for it until
      //    it's hydrated, and then finally show the tooltip
      function waitForWebComponentHydration() {
        if (node.hasAttribute('hydrated')) {
          requestAnimationFrame(() => {
            setIsMarketTooltipLoaded(true);
          });
        } else {
          setTimeout(() => {
            waitForWebComponentHydration();
          }, 10);
        }
      }

      if (node !== null) {
        waitForWebComponentHydration();
      }
    },
    []
  );

  const textContent = (
    <Box slot="trigger" as="span" className={styles.text}>
      {text}
    </Box>
  );

  return (
    <Box as="span" className={styles.tooltip} testId="tooltip">
      {!isMarketTooltipLoaded && textContent}
      {!isServer && (
        <Box
          as="span"
          className={clsx(!isMarketTooltipLoaded && styles.hidden)}
        >
          <MarketTooltip popoverPlacement="top" ref={onMarketRef}>
            {textContent}
            <Box slot="content" testId="tooltip-content" as="span">
              {children}
            </Box>
          </MarketTooltip>
        </Box>
      )}
    </Box>
  );
};

export { Tooltip };
