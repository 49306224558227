import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from './highlighter';

interface TooltipRegexGroup {
  text: string;
  content: string;
}

const TooltipRegex = /(?<text>[\dA-Za-z]+)!!(?<content>[^!]+)!!/g;

/**
 * Simple syntax transformer that turns the tooltip syntax of `<text>!!<tooltipContent>!! into
 * {% tooltip text="<text>" %}<tooltipContent>{% /tooltip %}
 */
const tooltipSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replaceAll(
        TooltipRegex,
        (original: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(original);
          }

          // Last arg is always the named group
          const groups: TooltipRegexGroup = args
            ? (args[args.length - 1] as TooltipRegexGroup)
            : { text: '', content: '' };

          const text = groups?.text ? `text="${groups.text}"` : '';
          const content = groups?.content || '';

          return `{% tooltip ${text} %}${content}{% /tooltip %}`;
        }
      ) || ''
    );
  },
};

export { tooltipSyntaxTransformer };
