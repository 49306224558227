import {
  Box,
  BoxProps,
  Paragraph20,
  Paragraph30,
  TextProps,
} from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { Link, LinkProps } from '../Link';

import styles from './sidebar-link.module.css';

type SidebarLinkProps = PropsWithChildren<
  {
    href: string;
    selected?: boolean;
    icon: ReactNode;
  } & Omit<LinkProps, 'omitAnchor'> &
    Partial<Pick<BoxProps, 'margin' | 'padding' | 'border'>>
>;

type ParagraphVariants = { paragraphVariant: '20' | '30' };

const ParagraphComponent = React.forwardRef<
  HTMLElement,
  TextProps<HTMLParagraphElement> & ParagraphVariants
>(({ paragraphVariant, ...props }, ref) => {
  if (paragraphVariant === '20') {
    return <Paragraph20 {...props} ref={ref} />;
  }
  if (paragraphVariant === '30') {
    return <Paragraph30 {...props} ref={ref} />;
  }
  return null;
});
ParagraphComponent.displayName = 'ParagraphComponent';

const SidebarLink: FC<Omit<SidebarLinkProps, 'icon'> & ParagraphVariants> = ({
  href,
  selected,
  children,
  className,
  margin,
  padding,
  border,
  paragraphVariant,
  ...linkProps
}) => {
  return (
    <Link
      href={href}
      omitAnchor={true}
      omitFocusStyling={true}
      passHref
      {...linkProps}
    >
      <ParagraphComponent
        as="a"
        colorVariant="20"
        paragraphVariant={paragraphVariant}
        className={clsx(
          styles['sidebar-link'],
          styles['sidebar-container'],
          selected && styles.selected,
          className
        )}
        margin={margin}
        padding={padding}
        border={border}
        trackingId={linkProps.trackingId}
        trackingExtra={linkProps.trackingExtra}
        testId={linkProps.testId}
      >
        {children}
      </ParagraphComponent>
    </Link>
  );
};

const SidebarLink20: FC<Omit<SidebarLinkProps, 'icon'>> = (props) => {
  return <SidebarLink {...props} paragraphVariant={'20'} />;
};

const SidebarLink30: FC<Omit<SidebarLinkProps, 'icon'>> = (props) => {
  return <SidebarLink {...props} paragraphVariant={'30'} />;
};

const SidebarLinkIcon: FC<SidebarLinkProps> = ({ icon, ...props }) => {
  return (
    <Box
      testId={`container-${props.testId}`}
      className={clsx(styles['sidebar-link-icon'])}
    >
      <Box testId="sidebar-link-icon" margin={{ right: '0.5x' }}>
        {icon}
      </Box>
      <SidebarLink {...props} paragraphVariant={'30'} />
    </Box>
  );
};

export { SidebarLink20, SidebarLink30, SidebarLinkIcon };
