import styles from './sticky-container.module.css';

/**
 * When a sticky element is in its "sticky" position,
 * add the attribute `is-stickied` to it.
 * The `top` of the container is the sum of the top and margin-top values.
 * So to clip the top of the viewport by 1px, and assuming the top and margin-top sum
 * to 156px, we need a root margin of -157px.
 * The bottom margin is set to be large so that it never
 * intersects, ensuring the `sticky` detector only works when
 * scrolling up. One day we might have a real `:stuck` pseudoselector
 * @param {*} e Element that can become sticky
 */
const addAttrWhenSticky = (
  element: HTMLElement,
  callback?: (isSticky: boolean) => void
): IntersectionObserver | undefined => {
  const top = Number.parseInt(
    getComputedStyle(element).top.replace('px', ''),
    10
  );

  if (!top) {
    return undefined;
  }

  const observer = new IntersectionObserver(
    ([e]) => {
      const isSticky = (e?.intersectionRatio || 0) < 1;
      e?.target.classList.toggle(styles['is-stickied'] as string, isSticky);
      e?.target.toggleAttribute('data-is-stickied', isSticky);
      callback?.(isSticky);
    },
    {
      threshold: [1],
      rootMargin: `-${top + 1}px 0px 10000px 0px`,
    }
  );

  observer.observe(element);
  return observer;
};

const stickyStyles = { 'is-stickied': styles['is-stickied'] };

export { addAttrWhenSticky, stickyStyles };
