import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import { TableHeader, TableHeaderProps } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownTableHeaderProps {}

const MarkdownTableHeader: FC<
  PropsWithChildren<MarkdownTableHeaderProps & TableHeaderProps>
> = ({ children, align, width, colspan }) => {
  return (
    <TableHeader align={align} width={width} colspan={colspan}>
      {children}
    </TableHeader>
  );
};

const schema = {
  render: 'MarkdownTableHeader',
  attributes: {
    align: { type: String, required: false, default: 'left' },
    width: { type: String, required: false, default: undefined },
    colspan: { type: Number, required: false, default: undefined },
  },
};

const tableHeader: MarkdownNode = {
  node: {
    nodeType: 'th',
    schema,
  },
  component: {
    name: schema.render,
    value: MarkdownTableHeader,
  },
};

export { tableHeader };
