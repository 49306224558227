import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from './highlighter';

type TocRegexGroup = {
  depth?: string;
  hide?: string;
};

const TOC_REGEX =
  /{TOC\s*(?<depth>depth=h3)?\s*(?<hide>hide=(?:true|false))?\s*}/;

/**
 * Simple syntax transformer that turns {TOC} into {% toc /%} to fit the
 * markdown expectations
 */
const tocSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replace(TOC_REGEX, (original: string, ...args: unknown[]) => {
        if (highlight) {
          return highlightText(original);
        }

        // Last arg is always the named group
        const groups: TocRegexGroup = args
          ? (args[args.length - 1] as TocRegexGroup)
          : {};

        const depth = groups?.depth === 'depth=h3' ? 'depth=3 ' : '';
        const hide = groups?.hide === 'hide=true' ? 'hide=true ' : '';

        return `{% toc ${depth}${hide}/%}`;
      }) || ''
    );
  },
};

export { tocSyntaxTransformer };
