import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';
import { AsideType } from '@squareup/dex-ui';

import { highlightText } from './highlighter';

interface AsideRegexGroup {
  type?: AsideType;
}

const AsideStartRegex = /[\t ]*!!!(?<type>[\t A-Za-z]*)/g;

/**
 * Simple syntax transformer that turns the aside syntax of !!!<type>\n<content>\n!!! into
 * {% aside type="<type>" %}<content>{% /aside %}
 *
 * This is done with a simple regex that treats !!![A-Za-z]+ as the start, and !!! as the end
 */
const asideSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replaceAll(
        AsideStartRegex,
        (original: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(original);
          }

          // Last arg is always the named group
          const groups: AsideRegexGroup = args
            ? (args[args.length - 1] as AsideRegexGroup)
            : {};

          const type = groups?.type?.trim()
            ? `type="${groups.type.trim()}"`
            : '';

          if (type) {
            return `{% aside ${type} %}`;
          } else {
            return '{% /aside %}';
          }
        }
      ) || ''
    );
  },
};

export { asideSyntaxTransformer };
