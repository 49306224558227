import { TocSection } from '@squareup/dex-types-shared-docs';
import { SidebarLink20, Accordion } from '@squareup/dex-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC, ReactNode } from 'react';

interface NarrowTableOfContentsProps {
  sections: Array<TocSection>;
  heading?: ReactNode;
  defaultExpanded?: boolean;
}

const NarrowTableOfContents: FC<NarrowTableOfContentsProps> = ({
  sections,
  heading,
  defaultExpanded = false,
}) => {
  if (sections.length === 0) {
    return null;
  }

  return (
    <Accordion
      defaultExpanded={defaultExpanded}
      title={heading}
      trackingId={'toc-accordion-clicked'}
    >
      {sections.map((section, i) => {
        const isLast = i === sections.length - 1;
        return (
          <Box
            key={`${section.name}_${i}`}
            margin={isLast ? undefined : { bottom: '1x' }}
          >
            <SidebarLink20
              omitFocusStyling={true}
              href={`#${section.id}`}
              trackingId={'sidebar-link-clicked'}
            >
              {section.name}
            </SidebarLink20>
          </Box>
        );
      })}
    </Accordion>
  );
};

export { NarrowTableOfContents };
