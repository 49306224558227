import * as React from 'react';
import { SVGProps } from 'react';

const SvgLeft = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.293 11.293a1 1 0 0 0 0 1.414l7 7 1.414-1.414L7.414 13H19v-2H7.414l5.293-5.293-1.414-1.414-7 7Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgLeft;
