import RightArrow from '@squareup/dex-icons/dex/arrow/Right';
import {
  MarkdownContext,
  useMarkdownContext,
} from '@squareup/dex-markdown-context-provider';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-docs-cta.module.css';

const EmptyLinkWrapper: Exclude<MarkdownContext['LinkComponent'], null> = ({
  children,
}) => <>{children}</>;

interface MarkdownDocsCtaProps {
  leadText?: string;
  href: string;
}

const MarkdownDocsCta: FC<PropsWithChildren<MarkdownDocsCtaProps>> = ({
  children,
  href,
  leadText,
}) => {
  const markdownContext = useMarkdownContext();
  const LinkWrapper = markdownContext.LinkComponent || EmptyLinkWrapper;

  return (
    <Box
      padding={{ vertical: '1.5x', horizontal: '1.5x' }}
      margin={{ top: '4x' }}
      border={{ radius: '8' }}
      className={styles['cta-container']}
    >
      <Paragraph20 weight="medium">{leadText}</Paragraph20>
      <LinkWrapper href={href} trackingId={'docs-cta'}>
        <Paragraph20
          as="a"
          className={styles.cta}
          weight="semi-bold"
          border={{ radius: '6' }}
          margin={{ left: '1.5x' }}
          padding={{ vertical: '1x', horizontal: '1.5x' }}
        >
          {children}
          <RightArrow className={commonIconStyles['icon-fill-current-color']} />
        </Paragraph20>
      </LinkWrapper>
    </Box>
  );
};

const schema = {
  render: 'MarkdownDocsCta',
  attributes: {
    leadText: { type: String, required: true, default: '' },
    href: { type: String, required: true, default: '' },
  },
};

const docsCta: MarkdownComponent = {
  tag: {
    name: 'docs-cta',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownDocsCta,
  },
};

export { docsCta };
