import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Tooltip } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

interface MarkdownTooltipProps {
  text: string;
}

const MarkdownTooltip: FC<PropsWithChildren<MarkdownTooltipProps>> = ({
  text,
  children,
}) => {
  return <Tooltip text={text}>{children}</Tooltip>;
};

const schema = {
  render: 'MarkdownTooltip',
  attributes: {
    text: { type: String, required: true, default: 'error' },
  },
};

const tooltip: MarkdownComponent = {
  tag: {
    name: 'tooltip',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownTooltip,
  },
};

export { tooltip };
