import RightArrow from '@squareup/dex-icons/dex/arrow/Right';
import {
  MarkdownContext,
  useMarkdownContext,
} from '@squareup/dex-markdown-context-provider';
import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box, Paragraph30 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-button-group.module.css';

const EmptyLinkWrapper: Exclude<MarkdownContext['LinkComponent'], null> = ({
  children,
}) => <>{children}</>;

interface MarkdownButtonGroupProps {
  primaryButtonText: string;
  secondaryButtonText: string;
  primaryHref: string;
  secondaryHref: string;
}

const MarkdownButtonGroup: FC<PropsWithChildren<MarkdownButtonGroupProps>> = ({
  primaryButtonText,
  secondaryButtonText,
  primaryHref,
  secondaryHref,
}) => {
  const markdownContext = useMarkdownContext();
  const LinkWrapper = markdownContext.LinkComponent || EmptyLinkWrapper;

  return (
    <Box margin={{ top: '4x' }} className={styles['container']}>
      <LinkWrapper
        href={primaryHref}
        trackingId={'markdown-button-group-primary'}
      >
        <Paragraph30
          as="a"
          className={styles.primary}
          weight="semi-bold"
          border={{ radius: '6' }}
          padding={{ vertical: '1.5x', horizontal: '2x' }}
        >
          {primaryButtonText}
          <RightArrow className={commonIconStyles['icon-fill-current-color']} />
        </Paragraph30>
      </LinkWrapper>
      <LinkWrapper
        href={secondaryHref}
        trackingId={'markdown-button-group-secondary'}
      >
        <Paragraph30
          as="a"
          className={styles.secondary}
          weight="semi-bold"
          border={{ radius: '6' }}
          margin={{ top: '2x' }}
          padding={{ vertical: '1.5x', horizontal: '2x' }}
        >
          {secondaryButtonText}
        </Paragraph30>
      </LinkWrapper>
    </Box>
  );
};

const schema = {
  render: 'MarkdownButtonGroup',
  attributes: {
    primaryButtonText: { type: String, required: true, default: '' },
    secondaryButtonText: { type: String, required: true, default: '' },
    primaryHref: { type: String, required: true, default: '' },
    secondaryHref: { type: String, required: true, default: '' },
  },
};

const markdownButtonGroup: MarkdownComponent = {
  tag: {
    name: 'markdown-button-group',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownButtonGroup,
  },
};

export { markdownButtonGroup };
