import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TestProps } from '@squareup/dex-types-shared-utils';
import { Box, Spacing } from '@squareup/dex-ui-shared-base';
import { responsiveStyles } from '@squareup/dex-ui-shared-responsive-helpers';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-layout.module.css';

interface MarkdownLayoutProps {
  direction?: 'row' | 'col';
  justify?: 'space-between' | 'space-evenly' | 'start' | 'end' | undefined;
  spaceAbove?: Spacing | undefined;
  hideWhen?: 'mobile' | 'desktop';
  wrap?: boolean;
  type?: 'grid' | 'flex' | undefined;
}

const MarkdownLayout: FC<
  PropsWithChildren<MarkdownLayoutProps & NullableClassName & TestProps>
> = ({
  children,
  justify,
  direction = 'col',
  spaceAbove,
  hideWhen,
  type = 'flex',
  className,
  wrap = false,
  testId,
}) => {
  return (
    <Box
      testId={testId}
      className={clsx(
        styles.layout,
        type && styles[type],
        direction && styles[direction],
        justify && styles[justify],
        wrap && styles.wrap,
        hideWhen === 'mobile' &&
          clsx(
            responsiveStyles[`tablet-${type}`],
            responsiveStyles[`desktop-${type}`]
          ),
        hideWhen === 'desktop' && responsiveStyles[`narrow-${type}`],
        className
      )}
      margin={spaceAbove ? { top: spaceAbove } : undefined}
    >
      {children}
    </Box>
  );
};

const schema = {
  render: 'MarkdownLayout',
  children: ['inline'],
  attributes: {
    direction: { type: String, required: false },
    justify: { type: String, required: false },
    spaceAbove: { type: String, required: false },
    hideWhen: { type: String, required: false },
    wrap: { type: Boolean, required: false },
  },
};

const layout: MarkdownComponent = {
  tag: {
    name: 'layout',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownLayout,
  },
};

export { layout, MarkdownLayout, type MarkdownLayoutProps };
