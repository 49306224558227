import { hasScrolledEndX, hasScrolledX } from '@squareup/dex-utils-dom';
import { RefObject, useEffect } from 'react';

import { addAttrWhenSticky } from '../StickyContainer';

import styles from './shim.module.css';

const useHorizontalShimOnScroll = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T | undefined>
) => {
  useEffect(() => {
    const currentRef = ref.current;
    currentRef?.classList.add(styles.setup as string);
    function onScroll() {
      if (!currentRef) {
        return;
      }

      // Since React will re-render the component without the CSS
      // transition if we use setState, we're going to toggle
      // the CSS manually
      if (hasScrolledX(currentRef)) {
        currentRef.classList.add(styles['shim-horizontal-left'] as string);
      } else {
        currentRef.classList.remove(styles['shim-horizontal-left'] as string);
      }

      if (!hasScrolledEndX(currentRef)) {
        currentRef.classList.add(styles['shim-horizontal-right'] as string);
      } else {
        currentRef.classList.remove(styles['shim-horizontal-right'] as string);
      }
    }

    onScroll();
    currentRef?.addEventListener('scroll', onScroll, { passive: true });
    return () => {
      currentRef?.removeEventListener('scroll', onScroll);
    };
  }, [ref]);
};

const useVerticalShimOnStickyHeader = <T extends HTMLElement = HTMLElement>(
  ref: RefObject<T | undefined>
) => {
  useEffect(() => {
    const currentRef = ref.current;

    if (!currentRef) {
      return () => null;
    }

    let observer: IntersectionObserver | undefined;

    // Sometimes the sticky header won't be there on the first render, and
    // the component itself might not rerender for a CSS style change.
    // So as a backup, we'll try adding the intersection observer on scroll
    function onFirstScroll() {
      if (!currentRef) {
        return;
      }

      observer = addAttrWhenSticky(currentRef, (isSticky) => {
        if (isSticky) {
          currentRef.classList.add(styles['shim-vertical-bottom'] as string);
        } else {
          currentRef.classList.remove(styles['shim-vertical-bottom'] as string);
        }
      });

      if (observer) {
        currentRef.classList.add(styles.setup as string);
      }

      if (observer) {
        document.removeEventListener('scroll', onFirstScroll);
      }
    }

    document.addEventListener('scroll', onFirstScroll, { passive: true });
    return () => {
      observer?.disconnect();
      document.removeEventListener('scroll', onFirstScroll);
    };
  }, [ref]);
};

export { useHorizontalShimOnScroll, useVerticalShimOnStickyHeader };
