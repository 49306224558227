import * as React from 'react';
import { SVGProps } from 'react';

const SvgUpDownLeftRight = (
  props: SVGProps<SVGSVGElement> & React.HTMLAttributes<HTMLElement>
) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 3H13V9H11V6.414L6.414 11H9V13H3V7H5V9.586L9.586 5H7V3Z"
      fill="#7B61FF"
    />
  </svg>
);

export default SvgUpDownLeftRight;
