import type { Placement } from '@popperjs/core';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box } from '@squareup/dex-ui-shared-base';
import clsx from 'clsx';
import React, {
  AriaAttributes,
  MouseEvent,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useId,
} from 'react';
import { usePopper } from 'react-popper';

import { Link, LinkProps } from '../Link';
import { NativeButton } from '../NativeButton';

import styles from './native-dropdown.module.css';

type NativeDropdownProps = {
  children?: ReactNode;
  selected: string;
  trigger?: ReactNode;
  placement?: Placement;
  buttonTestId?: string | undefined;
  listTestId?: string | undefined;
} & NullableClassName &
  TrackedProps &
  AriaAttributes;

type NativeDropdownRowProps = {
  children: ReactNode;
  value: string;
  itemClassName?: string | undefined;
  onSelected?: ((event: MouseEvent, value: string) => void) | undefined;
} & LinkProps &
  NullableClassName &
  TrackedProps;

const exportedStyles = {
  primary: styles.primary,
  secondary: styles.secondary,
};

const NativeDropdownContext = React.createContext({
  selected: '',
  closeDropdown: () => {},
});

const NativeDropdownRow = ({
  value,
  className,
  itemClassName,
  children,
  onSelected,
  trackingId,
  trackingExtra,
  href,
}: NativeDropdownRowProps) => {
  const dropdownState = useContext(NativeDropdownContext);

  const memoizedOnClick = useCallback(
    (event: MouseEvent) => {
      onSelected && onSelected(event, value);
      dropdownState.closeDropdown();
    },
    [onSelected, dropdownState, value]
  );

  const wrappedChildren = (
    <Box
      role="menuitem"
      padding={{ horizontal: '1x', vertical: '0.5x' }}
      border={{ radius: 'standard' }}
      className={clsx(
        styles.row,
        dropdownState.selected === value && styles.selected,
        styles['row-interactable'],
        className
      )}
    >
      {children}
    </Box>
  );

  return href ? (
    <Link
      trackingId={`${trackingId}-row`}
      trackingExtra={trackingExtra}
      omitFocusSpacing={true}
      className={itemClassName}
      onClick={memoizedOnClick}
      href={href}
    >
      {wrappedChildren}
    </Link>
  ) : (
    <NativeButton
      trackingId={trackingId}
      trackingExtra={trackingExtra}
      omitFocusSpacing={true}
      className={clsx(itemClassName, styles['row-interactable'])}
      onClick={memoizedOnClick}
    >
      {wrappedChildren}
    </NativeButton>
  );
};

const NativeDropdown = ({
  children,
  className,
  selected,
  trigger,
  placement,
  buttonTestId,
  listTestId,
  trackingId,
  trackingExtra,
  ...rest
}: NativeDropdownProps) => {
  const menuId = useId();
  const [referenceElement, setReferenceElement] = useState(null);
  const [popperElement, setPopperElement] = useState(null);
  const { styles: popoverStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: placement || 'bottom',
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [-8, 8],
          },
        },
      ],
    }
  );

  const [showDropdown, setShowDropdown] = useState(false);

  function onDropdownClicked() {
    if (children) {
      setShowDropdown(!showDropdown);
    }
  }

  useEffect(() => {
    function closeOnClickAway(e: Event) {
      if (!showDropdown) {
        return;
      }

      if (
        !(referenceElement as unknown as HTMLElement)?.contains(
          e.target as HTMLElement
        ) &&
        !(popperElement as unknown as HTMLElement)?.contains(
          e.target as HTMLElement
        )
      ) {
        setShowDropdown(false);
      }
    }

    document.addEventListener('click', closeOnClickAway);

    return function cleanup() {
      document.removeEventListener('click', closeOnClickAway);
    };
  }, [showDropdown, popperElement, referenceElement]);

  return (
    <NativeDropdownContext.Provider
      value={{
        selected,
        closeDropdown: () => {
          setShowDropdown(false);
        },
      }}
    >
      <NativeButton
        trackingId={trackingId}
        trackingExtra={trackingExtra}
        testId={buttonTestId}
        className={clsx(className)}
        onClick={onDropdownClicked}
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref={setReferenceElement as any}
        aria-haspopup={'true'}
        aria-expanded={showDropdown}
        aria-controls={menuId}
        {...rest}
      >
        {trigger || selected}
      </NativeButton>
      {showDropdown && (
        <div
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ref={setPopperElement as any}
          data-testid={listTestId}
          // eslint-disable-next-line react/forbid-dom-props
          style={{ ...popoverStyles.popper, zIndex: 2 }}
          {...attributes.popper}
        >
          <Box
            id={menuId}
            className={clsx(styles.popover)}
            padding={{ horizontal: '1x', vertical: '1x' }}
            border={{ radius: 'standard' }}
            shadow="30"
            role="menu"
          >
            {children}
          </Box>
        </div>
      )}
    </NativeDropdownContext.Provider>
  );
};

export {
  NativeDropdown,
  NativeDropdownRow,
  exportedStyles as nativeDropdownRowStyles,
};
