import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import { Image } from '@squareup/dex-ui-shared-image';
import clsx from 'clsx';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import styles from './doc-page-banner.module.css';

interface DocPageBannerProps {
  imagePath?: string | undefined;
  imageAlt?: string | undefined;
  customImage?: ReactNode;
}

const DocPageBanner: FC<
  PropsWithChildren<DocPageBannerProps & NullableClassName>
> = ({ children, imagePath, imageAlt, className, customImage }) => {
  return (
    <Box className={clsx(styles.banner, className)} margin={{ bottom: '8x' }}>
      {imagePath && (
        <Image
          className={styles['banner-image']}
          {...{ src: imagePath, alt: imageAlt || 'banner' }}
          width={479}
          height={308}
          priority={true}
        />
      )}
      {customImage && (
        <Box className={clsx(styles['banner-image'], styles['custom-image'])}>
          {customImage}
        </Box>
      )}
      <Box className={styles.content}>{children}</Box>
    </Box>
  );
};

export { DocPageBanner };
