import { MarkdownSyntaxTransformer } from '@squareup/dex-types-shared-markdown';

import { highlightText } from '../highlighter';

const anchorRegex = /<video.*src="(?<src>[\w./:-]+)".*><\/video\s*>/g;

interface VideoRegexGroup {
  src: string;
}

/**
 * A transformer to convert <video> elements to ![name](video.mp4)
 */
const htmlVideoSyntaxTransformer: MarkdownSyntaxTransformer = {
  transform(
    content: string | undefined,
    highlight?: boolean | undefined
  ): string {
    return (
      content?.replaceAll(
        anchorRegex,
        (original: string, ...args: unknown[]) => {
          if (highlight) {
            return highlightText(original);
          }

          // Last arg is always the named group
          const groups: VideoRegexGroup = args
            ? (args[args.length - 1] as VideoRegexGroup)
            : { src: '' };

          return `![video](${groups.src})`;
        }
      ) || ''
    );
  },
};

export { htmlVideoSyntaxTransformer };
