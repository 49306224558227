import { StaticImport } from 'next/dist/shared/lib/get-img-props';

interface LoaderProps {
  src: string;
  width: number;
  quality?: number | undefined;
}

const contentfulImageHost = 'images.ctfassets.net';
const contentfulConvertibleExtensions = ['png'];
const contentfulValidImgExtension = 'webp';
const contentfulImgFmtSearchParam = 'fm';

const defaultQuality = 75;

const isContentfulImageURL = (url: URL): boolean => {
  return url.hostname === contentfulImageHost;
};

const isContentfulImageSrc = (src: string | StaticImport): boolean => {
  if (typeof src !== 'string') {
    return false;
  }

  try {
    const url = new URL(src.startsWith('//') ? `https:${src}` : src);
    return isContentfulImageURL(url);
  } catch {
    return false;
  }
};

/**
 * Contentul offers an automatic image conversion in their image assets API
 * https://www.contentful.com/developers/docs/references/images-api/#/reference/changing-formats
 *
 * You simply have to add a query parameter to the end, such as ?fm=webp
 * Since certain formats like `png`s are very large (ex 100kb), the `webp` format can heavily reduce
 * the size (ex 100kb -> 15kb). This will also greatly speed up initial load
 * @param src The image to attempt to convert
 * @returns A new src string with ?fm=webp if possible, otherwise returns the original src
 */
const convertContentfulImageSrc = (src: string): string => {
  try {
    const url = new URL(src.startsWith('//') ? `https:${src}` : src);
    // Only modify Contentful images
    if (!isContentfulImageURL(url)) {
      return url.toString();
    }

    // If the writer intentionally set a format, we don't modify it
    if (url.searchParams.has(contentfulImgFmtSearchParam)) {
      return url.toString();
    }

    if (
      !contentfulConvertibleExtensions.some((ext) =>
        url.pathname.endsWith(`.${ext}`)
      )
    ) {
      return url.toString();
    }

    url.searchParams.set(
      contentfulImgFmtSearchParam,
      contentfulValidImgExtension
    );

    return url.toString();
  } catch {
    return src;
  }
};

const contentfulImageLoader = ({ src, width, quality }: LoaderProps) => {
  try {
    const url = new URL(src);
    if (!isContentfulImageURL(url)) {
      return src;
    }

    url.searchParams.append('w', width.toString());
    url.searchParams.append('q', (quality || defaultQuality).toString());

    return url.toString();
  } catch {
    // Can't process this url
    return src;
  }
};

/**
 * This function will attempt to parse the width and height from a Contentful image src
 * Otherwise, it returns undefined;
 * @param src The src to parse. This function only supports converting Contentful images for now
 * @returns An object with the width and height of the image if specified
 */
const parseDimensionsFromSrc = (
  src: string
): { width: number | undefined; height: number | undefined } => {
  try {
    const url = new URL(src);
    if (!isContentfulImageURL(url)) {
      return { width: undefined, height: undefined };
    }

    const width = url.searchParams.get('w');
    const height = url.searchParams.get('h');

    return {
      width: width ? Number.parseInt(width, 10) : undefined,
      height: height ? Number.parseInt(height, 10) : undefined,
    };
  } catch {
    return { width: undefined, height: undefined };
  }
};

export {
  convertContentfulImageSrc,
  contentfulImageLoader,
  isContentfulImageSrc,
  isContentfulImageURL,
  parseDimensionsFromSrc,
};
