import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Box } from '@squareup/dex-ui-shared-base';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-card.module.css';

interface MarkdownCardImageOffsetProps {}

const MarkdownCardImageOffset: FC<
  PropsWithChildren<MarkdownCardImageOffsetProps>
> = ({ children }) => {
  return <Box className={styles['card-image-offset']}>{children}</Box>;
};

const schema = {
  render: 'MarkdownCardImageOffset',
};

const cardImageOffset: MarkdownComponent = {
  tag: {
    name: 'card-image-offset',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCardImageOffset,
  },
};

export { cardImageOffset };
