import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box, Paragraph30 } from '@squareup/dex-ui-shared-base';
import { Image } from '@squareup/dex-ui-shared-image';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import styles from './doc-resource-info.module.css';

interface DocResourceInfoProps {
  iconPath: string;
  iconAlt: string;
  title?: string | undefined;
}

const DocResourceInfo: FC<
  PropsWithChildren<DocResourceInfoProps & NullableClassName>
> = ({ children, className, iconPath, iconAlt, title }) => {
  return (
    <Box className={clsx(styles.container, className)}>
      {iconPath && (
        <Image
          className={styles.image}
          src={iconPath}
          alt={iconAlt}
          width={40}
          height={40}
        />
      )}
      <Box>
        {title && <Paragraph30 weight="semi-bold">{title}</Paragraph30>}
        <Paragraph30 weight="semi-bold" colorVariant="30">
          {children}
        </Paragraph30>
      </Box>
    </Box>
  );
};

export { DocResourceInfo };
