import { MarkdownComponent } from '@squareup/dex-types-shared-markdown';
import { Carousel } from '@squareup/dex-ui';
import React, { FC, PropsWithChildren } from 'react';

import styles from './markdown-carousel.module.css';

interface MarkdownCarouselProps {}

const MarkdownCarousel: FC<PropsWithChildren<MarkdownCarouselProps>> = ({
  children,
}) => {
  return (
    <Carousel margin={{ top: '4x' }} className={styles.carousel}>
      {children}
    </Carousel>
  );
};

const schema = {
  render: 'MarkdownCarousel',
  attributes: {},
};

const carousel: MarkdownComponent = {
  tag: {
    name: 'carousel',
    schema,
  },
  component: {
    tagName: schema.render,
    value: MarkdownCarousel,
  },
};

export { carousel };
