import RighArrow from '@squareup/dex-icons/dex/arrow/Right';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Paragraph30 } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import clsx from 'clsx';
import React, { FC, PropsWithChildren } from 'react';

import { focusRingStyles } from '../FocusRing';
import { Link } from '../Link';

import styles from './doc-card.module.css';

interface DocCardLinkOutProps {
  href?: string | undefined;
}

const LinkWrapper: FC<
  PropsWithChildren<DocCardLinkOutProps & NullableClassName & TrackedProps>
> = ({ children, href, trackingId }) => {
  return href ? (
    <Link passHref omitAnchor={true} href={href} trackingId={trackingId}>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

const DocCardLinkOut: FC<
  PropsWithChildren<DocCardLinkOutProps & NullableClassName>
> = ({ children, href, className }) => {
  return (
    <LinkWrapper href={href} trackingId={'doc-card-link-out'}>
      <Paragraph30
        testId="doc-card-link-out"
        as={href ? 'a' : 'span'}
        weight="semi-bold"
        className={clsx(
          styles['doc-card-link-out'],
          focusRingStyles['focus-ring'],
          className
        )}
      >
        {children}
        <RighArrow
          className={clsx(
            styles['link-out-icon'],
            commonIconStyles['icon-fill-current-color']
          )}
        />
      </Paragraph30>
    </LinkWrapper>
  );
};

export { DocCardLinkOut };
