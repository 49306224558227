import { Box, BoxProps, Paragraph20 } from '@squareup/dex-ui-shared-base';
import { setLocalAndForwardedRef } from '@squareup/dex-utils-dom';
import clsx from 'clsx';
import React, {
  DOMAttributes,
  FC,
  forwardRef,
  PropsWithChildren,
  useRef,
} from 'react';

import {
  useHorizontalShimOnScroll,
  useVerticalShimOnStickyHeader,
} from '../Shim';

import styles from './table.module.css';

interface TableProps {
  hasStickyHeader?: boolean | undefined;
}

const Table = forwardRef<
  HTMLElement,
  PropsWithChildren<TableProps & Partial<Pick<BoxProps, 'margin' | 'padding'>>>
>(({ children, margin, padding, hasStickyHeader = false }, ref) => {
  const tableRef = useRef<HTMLElement>();

  useHorizontalShimOnScroll(tableRef);

  return (
    <Box margin={margin} padding={padding} className={clsx(styles.outer)}>
      <Box
        testId="table"
        ref={setLocalAndForwardedRef(tableRef, ref)}
        className={clsx(
          styles.container,
          hasStickyHeader && styles['overflow-visible']
        )}
      >
        <Box
          as="table"
          className={clsx(
            styles.table,
            hasStickyHeader && styles['sticky-header']
          )}
        >
          {children}
        </Box>
      </Box>
    </Box>
  );
});
Table.displayName = 'Table';

interface TableHeadProps {}

const TableHead: FC<PropsWithChildren<TableHeadProps>> = ({ children }) => {
  const tableHeadRef = useRef<HTMLElement>();
  useVerticalShimOnStickyHeader(tableHeadRef);
  return (
    <Box ref={tableHeadRef} as="thead">
      {children}
    </Box>
  );
};

interface TableHeaderProps {
  align?: 'left' | 'right' | 'center' | 'justify' | undefined;
  width?: string | undefined;
  colspan?: number | undefined;
}

const TableHeader: FC<PropsWithChildren<TableHeaderProps>> = ({
  align = 'left',
  children,
  width,
  colspan,
}) => {
  const TableHeaderComponent = Paragraph20 as typeof Paragraph20 &
    FC<HTMLTableCellElement>;

  const spans = {
    colSpan: colspan,
  };

  return (
    <TableHeaderComponent
      as="th"
      weight="medium"
      border={{ line: { bottom: 'standard' } }}
      padding={{ horizontal: '1x', vertical: '1.5x' }}
      className={clsx(styles[align])}
      // This is one of the few times we will use inline styling,
      // since the width of a header should be done inline
      style={{ width }}
      {...spans}
    >
      {children}
    </TableHeaderComponent>
  );
};

interface TableDataProps {
  align?: 'left' | 'right' | 'center' | 'justify' | undefined;
  colspan?: number | undefined;
  rowspan?: number | undefined;
}

const TableData: FC<PropsWithChildren<TableDataProps>> = ({
  align = 'left',
  colspan,
  rowspan,
  children,
}) => {
  const TableDataComponent = Paragraph20 as typeof Paragraph20 &
    DOMAttributes<HTMLTableCellElement>;

  const spans = {
    colSpan: colspan,
    rowSpan: rowspan,
  };

  return (
    <TableDataComponent
      as="td"
      border={{ line: { bottom: '20' } }}
      padding={{ vertical: '1.5x', horizontal: '1x' }}
      className={clsx(styles[align])}
      {...spans}
    >
      {children}
    </TableDataComponent>
  );
};

export {
  Table,
  TableHeader,
  type TableHeaderProps,
  TableData,
  type TableDataProps,
  TableHead,
};
