import { MarkdownNode } from '@squareup/dex-types-shared-markdown';
import {
  bannedItems,
  doc,
  heading,
  inlineCode,
  link,
  table,
  tableHeader,
  tableData,
  tableHead,
  basicFence as fence,
  image,
} from '@squareup/dex-ui-shared-markdown-components';

const nodes: Array<MarkdownNode> = [
  doc,
  heading,
  link,
  fence,
  image,
  inlineCode,
  table,
  tableHeader,
  tableData,
  tableHead,
  ...bannedItems,
];
export { nodes };
