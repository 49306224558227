import { TocSection } from '@squareup/dex-types-shared-docs';
import { createContext } from 'react';

interface MarkdownTocContext {
  sections: Array<TocSection>;
}

const MarkdownTocContextInstance = createContext<MarkdownTocContext>({
  sections: [],
});

const MarkdownTocProvider = MarkdownTocContextInstance.Provider;

export {
  MarkdownTocProvider,
  MarkdownTocContextInstance,
  type MarkdownTocContext,
};
