import { useTransition, animated, useSpringRef } from '@react-spring/web';
import { NullableClassName } from '@squareup/dex-types-shared-ui';
import { Box } from '@squareup/dex-ui-shared-base';
import React, {
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { tabLabelToAriaRoleId } from './tab-utils';

interface TabAnimatorTabItem {
  tabId: string | undefined;
  contents: ReactNode;
}

interface TabAnimatorProps {
  tabs: Array<TabAnimatorTabItem>;
  currentTab: string;
}

const animationDuration = 160;

const shiftAmount = '56px';
const leftPositionAnimation = {
  opacity: 0,
  transform: `translateX(-${shiftAmount})`,
};
const rightPositionAnimation = {
  opacity: 0,
  transform: `translateX(${shiftAmount})`,
};
const restPositionAnimation = { opacity: 1, transform: 'translateX(0px)' };

/**
 * To make the animations work properly, this component takes in all the tabs, and the current tab,
 * and pushes these through the react-spring [useTransition](https://www.react-spring.dev/docs/components/use-transition)
 * hook to animate.
 *
 * If moving right, the animation shifts right. If moving left, it shifts left.
 */
const TabAnimator: FC<TabAnimatorProps & NullableClassName> = ({
  tabs = [],
  currentTab,
  className,
}) => {
  const springRef = useSpringRef();
  const contentRef = useRef<HTMLDivElement>(null);
  const prevTabIndexRef = useRef<number>(0);
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    if (tabs.length > 1) {
      springRef.start();
    }
  }, [springRef, tabs.length, currentTab]);

  // We need to ensure we show content on SSR, especially
  // if they don't have JS
  useEffect(() => {
    setIsClient(true);
  }, []);

  // Keep a map of the tabs based on their index in the array
  const tabIdByPositionMap = useMemo(() => {
    return new Map<string, number>(
      tabs.map((tab, i) => {
        return [tab.tabId || 'tab', i];
      })
    );
  }, [tabs]);

  const [transitions] = useTransition(currentTab, () => ({
    immediate: !contentRef.current,
    from: () => {
      const nextTabIndex = tabIdByPositionMap.get(currentTab) || 0;

      return nextTabIndex < prevTabIndexRef.current
        ? rightPositionAnimation
        : leftPositionAnimation;
    },
    enter: restPositionAnimation,
    leave: (item: string) => {
      const nextTabIndex = tabIdByPositionMap.get(currentTab) || 0;
      const prevTabIndex = tabIdByPositionMap.get(item) || 0;
      const result =
        nextTabIndex < prevTabIndex
          ? leftPositionAnimation
          : rightPositionAnimation;

      prevTabIndexRef.current = prevTabIndex;
      return result;
    },
    config: {
      duration: animationDuration,
    },
    ref: springRef,
    exitBeforeEnter: true,
  }));

  if (tabs.length === 0) {
    return <></>;
  }

  if (tabs.length === 1 || !isClient) {
    return (
      <Box
        {...(currentTab && {
          role: 'tabpanel',
          id: tabLabelToAriaRoleId(currentTab),
        })}
      >
        {tabs[0]?.contents}
      </Box>
    );
  }

  const animatedContents = transitions((style, currentTab) => {
    const contents =
      tabs.find((tab) => tab.tabId === currentTab)?.contents || null;
    return <animated.div style={style}>{contents}</animated.div>;
  });

  return (
    <Box
      ref={contentRef}
      className={className}
      {...(currentTab && {
        role: 'tabpanel',
        id: tabLabelToAriaRoleId(currentTab),
      })}
    >
      {animatedContents}
    </Box>
  );
};

export { TabAnimator, animationDuration, type TabAnimatorTabItem };
