import ChevronDown from '@squareup/dex-icons/dex/control/ChevronDown';
import ChevronUp from '@squareup/dex-icons/dex/control/ChevronUp';
import { TrackedProps } from '@squareup/dex-types-shared-utils';
import { Box, BoxProps } from '@squareup/dex-ui-shared-base';
import { commonIconStyles } from '@squareup/dex-ui-shared-icon-styles';
import clsx from 'clsx';
import React, {
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useId,
  useState,
} from 'react';

import { NativeButton } from '../NativeButton';

import styles from './accordion.module.css';

interface AccordionProps {
  defaultExpanded: boolean;
  title: ReactNode;
}

const Accordion: FC<
  PropsWithChildren<AccordionProps & TrackedProps & Pick<BoxProps, 'padding'>>
> = ({
  defaultExpanded,
  children,
  title,
  trackingId,
  trackingExtra,
  padding,
}) => {
  const id = useId();
  const [expanded, setExpanded] = useState(defaultExpanded);

  const onClick = useCallback(() => {
    setExpanded(!expanded);
  }, [expanded]);

  return (
    <>
      <NativeButton
        aria-expanded={expanded ? 'true' : 'false'}
        aria-controls={id}
        className={styles.accordion}
        trackingId={trackingId}
        trackingExtra={trackingExtra}
        onClick={onClick}
        margin={{ vertical: '1x' }}
        padding={padding}
      >
        {Array.isArray(title)
          ? title.map((heading, i) => <Fragment key={i}>{heading}</Fragment>)
          : title}
        {expanded ? (
          <ChevronUp
            className={commonIconStyles['icon-color']}
            viewBox="0 0 16 16"
            width={20}
            height={20}
          />
        ) : (
          <ChevronDown
            className={commonIconStyles['icon-color']}
            viewBox="0 0 16 16"
            width={20}
            height={20}
          />
        )}
      </NativeButton>
      <Box
        role="region"
        id={id}
        className={clsx(styles.container, expanded && styles.expanded)}
        inert={!expanded ? 'true' : undefined}
      >
        <Box className={styles.content}>{children}</Box>
      </Box>
    </>
  );
};

export { Accordion };
