const isScrollableX = <T extends HTMLElement>(e: T) => {
  return e.scrollWidth > e.clientWidth;
};

const isScrollableY = <T extends HTMLElement>(e: T) => {
  return e.scrollHeight > e.clientHeight;
};

const isScrollable = <T extends HTMLElement>(e: T) => {
  return isScrollableY(e) || isScrollableX(e);
};

const hasScrolledX = <T extends HTMLElement>(e: T) => {
  return e.scrollLeft > 0;
};

const hasScrolledY = <T extends HTMLElement>(e: T) => {
  return e.scrollTop > 0;
};

const hasScrolledEndX = <T extends HTMLElement>(e: T) => {
  return Math.round(e.scrollLeft + e.clientWidth) >= e.scrollWidth;
};

const hasScrolledEndY = <T extends HTMLElement>(e: T) => {
  return Math.round(e.scrollTop + e.clientHeight) >= e.scrollHeight;
};

const isElementVisibleInScrollableElement = <
  T extends HTMLElement,
  U extends HTMLElement
>(
  e: T,
  scrollable: U
) => {
  const rect = e.getBoundingClientRect();
  const scrollableRect = scrollable.getBoundingClientRect();

  return (
    rect.top >= scrollableRect.top &&
    rect.left >= scrollableRect.left &&
    rect.bottom <= scrollableRect.bottom &&
    rect.right <= scrollableRect.right
  );
};

export {
  isScrollable,
  isScrollableX,
  isScrollableY,
  hasScrolledX,
  hasScrolledY,
  hasScrolledEndX,
  hasScrolledEndY,
  isElementVisibleInScrollableElement,
};
